import React from 'react'
import './manage-roles.css';
import Spinner from '../../spinner';
import EditRole from './edit-role';


class ManageRoles extends React.Component {

    state = {
        isLoading: false,
        roles: [],
        newRoleName: '',
        selectedRole: null
    };

    newRoleNameChanged = (e) => {
        this.setState({
            newRoleName: e.target.value
        })
    };

    addNewRole = () => {
        const { apiService } = this.props;
        const { newRoleName } = this.state;

        if (!newRoleName) {
            return;
        }

        this.setState({ isLoading: true });
        apiService.addRole(newRoleName)
            .then(res => {
                if (res && res.success) {
                    this.loadRoles();
                }
                this.setState({
                    newRoleName: ''
                })
            });
    };

    removeRole = (role) => {
        const { apiService } = this.props;
        const conf = window.confirm(`Вилучити роль ${role.name}?`)
        if (!role || !conf) {
            return;
        }

        this.setState({ isLoading: true });
        apiService.removeRole(role)
            .then(res => {
                if (res && res.success) {
                    this.loadRoles();
                } else {
                    console.log(res);
                }
            });
    };


    loadRoles = (loading) => {
        const { apiService } = this.props;

        if (!loading) {
            this.setState({ isLoading: true });
        }
        apiService.getRoles()
            .then(res => {
                this.setState({
                    roles: res ?? [],
                    isLoading: false
                })
            });
    }

    componentDidMount = () => {
        this.loadRoles();
    }

    render() {
        const { roles, isLoading, selectedRole } = this.state;
        if (isLoading) {
            return <Spinner onTop />
        }

        const rolesList = roles.map(r => (
            <div className='role-row' key={r.roleId}>
                <div className='name'>{r.name}</div>
                <div className='actions'>
                    <div className='edit action-button' onClick={() => this.setState({ selectedRole: r })}>Редагувати</div>
                    {r.name !== 'Admin'
                        ? (<div className='remove action-button' onClick={() => this.removeRole(r)}>Вилучити</div>)
                        : null}
                </div>
            </div>
        ));

        const rolesView = (
            <div className='manage-roles'>
                <div className='roles'>
                    {rolesList}
                </div>
                <hr />
                <div className='add-new'>
                    <div className='name-box'>
                        <input type="text" value={this.state.newRoleName} onChange={this.newRoleNameChanged}></input>
                    </div>
                    <div className='add action-button' onClick={this.addNewRole}>Додати</div>
                </div>
            </div>);

        return selectedRole
            ? <EditRole 
                role={selectedRole} 
                onBack={() => this.setState({ selectedRole: null })} 
                apiService={this.props.apiService} />
            : rolesView;
    }
};

export default ManageRoles;