import React from 'react';
import { connect } from 'react-redux';
import { userSignedOut } from '../../actions';
import { withRouter } from 'react-router-dom'
import './header.css';

const Header = ({ history, userSignedOut, customer, i18n, goHomeOnSignOut }) => {
    return (
        <div className='main-header'>
            <div className='logo kredens-logo'></div>
            <div className='info' onClick={() => history.push('/account')}>
                <div className='welcomeText'>{i18n.hi(customer.name)}</div>
                <div className='bonuses'>{customer.bonuses > 0 ? i18n.bonusesCoffee(customer.bonuses) : i18n.noBonuses}</div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ i18n, customer }) => {
    return { i18n, customer }
};

export default connect(mapStateToProps, { userSignedOut })(withRouter(Header));