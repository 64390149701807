import React, { Fragment } from 'react';
import VestaButton from '../../vesta-button';
import CartHeader from './cart-header';
import { calculateTotal, getOrderItemFullPrice } from '../../../common';
import './edit-item.css'

const EditItem = ({
    selectedProduct,
    addToOrderClick,
    incClick,
    decClick,
    additionClick,
    i18n,
    closeClick,
    removeClick,
    order
}) => {

    const getProductView = () => {
        const selectedItem = selectedProduct.orderItems[0];
        let currentGroupNum = 0;
        const additionGroupNumCount = selectedProduct.availableAdditions
            .reduce((g, a) => {
                if (g.length < a.groupNum + 1) {
                    g.push(a.groupNum);
                } else {
                    g[a.groupNum]++
                }
                return g;
            }, []);
        const additions = selectedProduct.availableAdditions.map(addition => {
            const checked = selectedItem.orderItemAdditions.find(a => a.productAdditionId === addition.productAdditionId)
                ? 'checked'
                : '';
            const separator = currentGroupNum !== addition.groupNum && additionGroupNumCount[addition.groupNum] > 1 ? 'separator' : '';
            currentGroupNum = addition.groupNum;
            return (
                <div className={`addition-row ${separator}`} onClick={() => additionClick(addition, selectedItem)} key={addition.productAdditionId}>
                    <div className={`${addition.groupNum > 0 && additionGroupNumCount[addition.groupNum] > 1 ? 'radio' : 'check-box'} ${checked}`}></div>
                    <span className='price'>{addition.price}</span>
                    <span className='uah'>₴</span>
                    <div className='name'>{i18n.prop(addition, 'name')}</div>
                </div>
            );
        });
        const description = i18n.prop(selectedItem, 'description');
        return (
            <Fragment>
                <div className='head'>
                    <div className='remove-button' onClick={removeClick}></div>
                    <div className='title'>{i18n.prop(selectedItem, 'name')}</div>
                </div>
                <div className='size'>{i18n.prop(selectedItem, 'size')}</div>
                <div className='description'>{description}</div>
                <div className='item-row'>
                    <div className='item' disabled={selectedItem.count === 0}>
                        <div className='price'>{getOrderItemFullPrice(selectedItem) * selectedItem.count}</div>
                        <div className='uah'>₴</div>
                    </div>
                    <div className='actions'>
                        <div className='dec' onClick={() => decClick(selectedItem)} disabled={selectedItem.count <= 0}>—</div>
                        <div className='value' disabled={selectedItem.count <= 0}>{selectedItem.count}</div>
                        <div className='inc' onClick={() => incClick(selectedItem)}>+</div>
                    </div>
                </div>
                <div className={`additions ${!additions.length ? 'hidden' : ''}`}>
                    <div className='header-label'>{i18n.additions}</div>
                    {additions}
                </div>
            </Fragment>
        );
    };
    const total = selectedProduct && order
        ? calculateTotal({
            ...order,
            orderItems: order.orderItems.filter(i => i.orderItemId !== selectedProduct.orderItems[0].orderItemId)
        }) + calculateTotal(selectedProduct)
        : calculateTotal(order);

    const productItemView = selectedProduct ? getProductView() : null;
    const empty = (
        <div className="empty-order">
            <div className="empty-logo empty-cart" />
            <div className='empty-description line1'>{i18n.emptyOrder1}</div>
            <div className='empty-description line2'>{i18n.emptyOrder2}</div>
        </div>
    );

    const bottom = !selectedProduct
        ? (
            <div className='bottom-empty'>{i18n.weHaveGoods}</div>
        )
        : (
            <div className='bottom'>
                <div className='total-label'>{i18n.totalLabel}</div>
                <div className='total'>{total}</div>
                <div className='uah'>₴</div>
            </div>
        );


    return (
        <div className='edit-item'>
            <div className='overlay' onClick={closeClick}></div>
            <div className='product-container'>
                <CartHeader title={i18n.editOrder} closeClick={closeClick} />
                {selectedProduct ? productItemView : empty}
            </div>
            <div className='footer'>
                <VestaButton
                    label={i18n.finishEdit}
                    onButtonClick={addToOrderClick}
                />
                {bottom}
            </div>
        </div >
    );
};

export default EditItem;