import actionTypes from '../consts/action-types';

const languageChanged = (lang) => {
    return {
        type: actionTypes.languageChanged,
        payload: lang
    }
};

const userSignedIn = (customerData, sessionGuid) => {
    return {
        type: actionTypes.userSignedIn,
        payload: {
            customerData: customerData,
            sessionGuid: sessionGuid
        }
    }
};

const userSignedOut = (customerData) => {
    return {
        type: actionTypes.userSignedOut,
        payload: customerData
    }
};

const productGroupsLoaded = (productGroups) => {
    return {
        type: actionTypes.productGroupsLoaded,
        payload: productGroups
    }
};

const signInDataChanged = (signInData) => {
    return {
        type: actionTypes.signInDataChanged,
        payload: signInData
    }
};

const specialsLoaded = (specials) => {
    return {
        type: actionTypes.specialsLoaded,
        payload: specials
    }
};

const addSelectedProductToOrderRequested = (item) => {
    return {
        type: actionTypes.addSelectedProductToOrderRequested,
        payload: item
    }
};

const orderPaymentRequested = () => {
    return {
        type: actionTypes.orderPaymentRequested
    }
};

const orderPaid = () => {
    return {
        type: actionTypes.orderPaid
    }
};

const addToOrderRequested = (product, productItem) => {
    return {
        type: actionTypes.addToOrderRequested,
        payload: {
            product: product,
            productItem: productItem
        }
    }
};

const addToOrderCanceled = () => {
    return {
        type: actionTypes.addToOrderCanceled
    }
};

const editOrderRequested = () => {
    return {
        type: actionTypes.editOrderRequested
    }
};

const editOrderDismissed = () => {
    return {
        type: actionTypes.editOrderDismissed
    }
};

const editOrderConfirmed = () => {
    return {
        type: actionTypes.editOrderConfirmed
    }
};

const incOrderItem = (item) => {
    return {
        type: actionTypes.incOrderItem,
        payload: item
    }
};

const decOrderItem = (item) => {
    return {
        type: actionTypes.decOrderItem,
        payload: item
    }
};

const removeOrderItem = (item) => {
    return {
        type: actionTypes.removeOrderItem,
        payload: item
    }
};

const changeAddition = (addition, item) => {
    return {
        type: actionTypes.changeAddition,
        payload: {
            addition: addition,
            item: item
        }
    }
};

const customerDataChanged = (changedData) => {
    return {
        type: actionTypes.customerDataChanged,
        payload: changedData
    }
};

const orderUpdated = (order) => {
    return {
        type: actionTypes.orderUpdated,
        payload: order
    }
};

const orderHasChanged = (state) => {
    return {
        type: actionTypes.orderHasChanged,
        payload: state
    }
};

const editOrderItemRequested = (product, item) => {
    return {
        type: actionTypes.editOrderItemRequested,
        payload: { selectedProduct: product, selectedOrderItem: item }
    }
};

const editOrderItemDismissed = () => {
    return {
        type: actionTypes.editOrderItemDismissed
    }
};

const editOrderItemConfirmed = () => {
    return {
        type: actionTypes.editOrderItemConfirmed
    }
};

const editOrderItemRemoveConfirmed = () => {
    return {
        type: actionTypes.editOrderItemRemoveConfirmed
    }
};

const locationsLoaded = (locations) => {
    return {
        type: actionTypes.locationsLoaded,
        payload: locations
    }
};

const locationSelected = (location) => {
    return {
        type: actionTypes.locationSelected,
        payload: location
    }
};


export {
    userSignedIn,
    userSignedOut,
    languageChanged,
    productGroupsLoaded,
    signInDataChanged,
    specialsLoaded,
    addSelectedProductToOrderRequested,
    incOrderItem,
    decOrderItem,
    changeAddition,
    removeOrderItem,
    orderPaymentRequested,
    addToOrderRequested,
    addToOrderCanceled,
    editOrderRequested,
    editOrderDismissed,
    editOrderConfirmed,
    orderPaid,
    customerDataChanged,
    orderUpdated,
    orderHasChanged,
    editOrderItemRequested,
    editOrderItemDismissed,
    editOrderItemConfirmed,
    editOrderItemRemoveConfirmed,
    locationsLoaded,
    locationSelected
};