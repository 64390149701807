import React, { useEffect, useState, useCallback, Fragment } from 'react';
import Spinner from '../../spinner';
import EditField from './edit-field'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './additions.css';

const ManageAdditions = (props) => {
    const { apiService, history, match } = props;
    const [loading, setLoading] = useState(false);
    const [additions, setAdditions] = useState([]);
    const [selectedAddition, setSelected] = useState(null);

    const load = useCallback(async () => {
        setLoading(true);
        apiService.getAdditions()
            .then((res) => {
                setAdditions(res);
                setLoading(false);
                setSelected(match && match.params.id !== '0'
                    ? res.find(a => a.productAdditionId.toString() === match.params.id)
                    : (match && match.params.id === '0'
                        ?
                        {
                            nameUa: '',
                            nameEn: '',
                            price: 0,
                            selected: false, 
                            groupNum: 0
                        } : null));
            });
    }, [apiService, match]);

    useEffect(() => {
        load();
    }, [load]);

    if (loading) {
        return <Spinner />
    }

    const saveSelectedAddition = () => {
        if (!selectedAddition) {
            return;
        }
        setLoading(true);
        apiService.saveAddition(selectedAddition)
            .then(res => {
                if (res) {
                    history.push(`/admin/managemenu/additions/${res.productAdditionId}`);
                }
            })
    };

    const back = () => {
        const backUrl = selectedAddition ? '/admin/managemenu/additions' : '/admin/managemenu';
        history.push(backUrl);
    };

    const selectedAdditionView = selectedAddition ? (
        <div className='edit-addition'>
            <EditField
                label='Назва'
                target={selectedAddition}
                property='nameUa'
                set={setSelected} />
            <EditField
                label='Назва (англ)'
                target={selectedAddition}
                property='nameEn'
                set={setSelected} />
            <EditField
                label='Ціна'
                target={selectedAddition}
                property='price'
                set={setSelected} />
            <EditField
                label='Група'
                target={selectedAddition}
                property='groupNum'
                set={setSelected} />
            <EditField
                label='Вибрано'
                type='checkbox'
                target={selectedAddition}
                property='selected'
                set={setSelected} />
            <div className='actions'>
                <div className='action-button' onClick={saveSelectedAddition}>Зберегти</div>
                <div className='action-button' onClick={back}>Відмінити</div>
            </div>
        </div>
    ) : null;

    const tableCell = (value) => (
        <TableCell style={{ padding: '2px' }}>{value}</TableCell>
    );

    const additionsView = (
        <Fragment>
            <TableContainer >
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            {tableCell('Назва')}
                            {tableCell('Назва (англ)')}
                            {tableCell('Ціна')}
                            {tableCell('Група')}
                            {tableCell('Вибрано')}
                            {tableCell('')}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {additions.map((a) => (
                            <TableRow key={a.productAdditionId}>
                                {tableCell(a.nameUa)}
                                {tableCell(a.nameEn)}
                                {tableCell(a.price)}
                                {tableCell(a.groupNum)}
                                {tableCell(a.selected ? 'x' : '')}
                                <TableCell><div className='action-button' onClick={() => history.push(`/admin/managemenu/additions/${a.productAdditionId}`)}>Редагувати</div></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className='action-button' onClick={() => history.push(`/admin/managemenu/additions/0`)}>Новий додаток</div>
        </Fragment>
    );

    return (
        <div className='additions'>
            <div className='header'>
                <div className='list-header'>Додатки{selectedAddition ? ` [${selectedAddition.nameUa}]` : null}</div>
                <div className='back action-button' onClick={back}>Назад</div>
            </div>
            {selectedAdditionView ?? additionsView}
        </div>
    );
};

export default ManageAdditions;