import React from 'react';

const Contacts = () => {
    return (
        <div className='contacts'>
            <div className='par'><b>ФОП:</b> Конрат Ігор Миронович</div>
            <div className='par'><b>ЄДРПОУ/ДРФО:</b> 2652506176</div>
            <div className='par'><b>Юридична адреса:</b><br />79013,  Львівська обл. м. Львів, вул. Глибока, буд.1 кв. 1</div>
            <div className='par'><b>Фактична адреса:</b><br />м.Львів, вул. Порохова 20д, секція 5</div>
            <div className='par'><b>Телефон:</b> +38032228271</div>
            <div className='par'><b>Email:</b> office@kredens.com.ua</div>

        </div>
    )
}

export default Contacts;