import React from 'react'
import Select from 'react-select'
import './manage-roles.css';
import Spinner from '../../spinner';


class EditRole extends React.Component {
    state = {
        isLoading: false,
        features: [],
        customers: [],
        selectedFeature: null,
        selectedCustomer: null,
        selectedRole: null
    };

    loadData = () => {
        const { apiService, role } = this.props;

        this.setState({ isLoading: true });
        apiService.getRoles()
            .then(res => {
                const apiRole = res.find(r => r.roleId === role.roleId);
                this.setState({ selectedRole: apiRole });
                apiService.getFeatures()
                    .then(resFeatures => {
                        apiService.getCustomers()
                            .then(resCustomers => {
                                this.setState({
                                    features: resFeatures ?? [],
                                    customers: resCustomers ?? [],
                                    isLoading: false
                                })
                            });

                    });
            });
    }

    selectedCustomerChanged = (e) => {
        this.setState({
            selectedCustomer: e.value
        })
    };

    selectedFeatureChanged = (e) => {
        this.setState({
            selectedFeature: e.value
        })
    };

    addCustomer = () => {
        const { selectedCustomer, selectedRole } = this.state;
        const { apiService } = this.props;
        if (!selectedCustomer) {
            return;
        }
        const selectedCustomerId = selectedCustomer.customerId;
        this.setState({
            isLoading: true,
            selectedCustomer: null
        });

        apiService.addCustomerRole(selectedCustomerId, selectedRole.roleId)
            .then(res => {
                this.loadData();
            });
    }

    removeCustomer = (customerRole) => {
        const { apiService } = this.props;
        const { selectedRole } = this.state;

        if (!customerRole || !window.confirm(`Ви хочете вилучити користувача ${customerRole.customer.name} з ролі ${selectedRole.name}?`)) {
            return;
        }

        this.setState({
            isLoading: true
        });

        apiService.removeCustomerRole(customerRole)
            .then(res => {
                this.loadData();
            });
    }

    addFeature = () => {
        const { selectedFeature, selectedRole } = this.state;
        const { apiService } = this.props;
        if (!selectedFeature) {
            return;
        }
        const selectedFeatureId = selectedFeature.featureId;
        this.setState({
            isLoading: true,
            selectedFeature: null
        });

        apiService.addFeatureRole(selectedFeatureId, selectedRole.roleId)
            .then(res => {
                this.loadData();
            });
    }

    removeFeature = (featureRole) => {
        const { apiService } = this.props;
        const { selectedRole } = this.state;

        if (!featureRole || !window.confirm(`Ви хочете вилучити функцію ${featureRole.feature.displayName} з ролі ${selectedRole.name}?`)) {
            return;
        }

        this.setState({
            isLoading: true
        });

        apiService.removeFeatureRole(featureRole)
            .then(res => {
                this.loadData();
            });
    }


    componentDidMount = () => {
        this.loadData();
    }

    render = () => {
        const { onBack } = this.props;
        const { isLoading, features, customers, selectedCustomer, selectedFeature, selectedRole } = this.state;
        if (isLoading || !selectedRole) {
            return <Spinner onTop />
        }

        const featureOptions = features
            .filter(f => !selectedRole.features.find(f1 => f1.feature.featureId === f.featureId))
            .map(f => { return { value: f, label: f.displayName } });

        const customerOptions = customers
            .filter(c => !selectedRole.customers.find(c1 => c1.customer.customerId === c.customerId))
            .map(c => { return { value: c, label: `${c.name}, ${c.phoneNumber}` } });

        const featuresList = selectedRole.features.map(f => (
            <div className='feature' key={f.feature.featureId} >
                <div className='name'>{f.feature.displayName}</div>
                <div className='remove action-button' onClick={() => this.removeFeature(f)}>Вилучити</div>
            </div>
        ));

        const customersList = selectedRole.customers.map(c => (
            <div className='customer' key={c.customer.customerId} >
                <div className='name'>{c.customer.name}, {c.customer.phoneNumber}</div>
                <div className='remove action-button' onClick={() => this.removeCustomer(c)}>Вилучити</div>
            </div>
        ));

        return (
            <div className='edit-role'>
                <div className='header'>
                    <div className='back action-button' onClick={onBack}>Назад до списку ролей</div>
                    <div className='name'>Роль <b>{selectedRole.name}</b></div>
                </div>
                <hr />
                <div className='features'>
                    <div className='header-label'>Доступ:</div>
                    {featuresList}
                    <div className='actions'>
                        <div className='add-new'>
                            <Select
                                placeholder='Виберіть доступ ролі'
                                options={featureOptions}
                                value={selectedFeature ? { value: selectedFeature, label: selectedFeature.displayName } : null}
                                onChange={this.selectedFeatureChanged}
                            />
                        </div>
                        <div className='action-button' onClick={this.addFeature}>Додати</div>
                    </div>

                </div>
                <hr />
                <div className='customers'>
                    <div className='header-label'>Користувачі:</div>
                    {customersList}
                    <div className='actions'>
                        <div className='add-new'>
                            <Select
                                placeholder='Виберіть користувача ролі'
                                options={customerOptions}
                                value={selectedCustomer ? { value: selectedCustomer, label: `${selectedCustomer.name}, ${selectedCustomer.phoneNumber}` } : null}
                                onChange={this.selectedCustomerChanged}
                            />
                        </div>
                        <div className='action-button' onClick={this.addCustomer}>Додати</div>
                    </div>
                </div>
            </div>);
    }
};

export default EditRole;