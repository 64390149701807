import React from 'react';
import './info-box.css';
import VestaButton from '../vesta-button';

const InfoBox = ({ buttonLabel, onButtonClick, title, description, className }) => {
    return (
        <div className={`info-box ${className ? className : ''}`}>
            <div className='title'>{title}</div>
            <div className='description'>{description}</div>
            <VestaButton
                theme='whitesolidborder'
                label={buttonLabel}
                onButtonClick={onButtonClick ? onButtonClick : () => { }}
            />
        </div>
    );
};

export default InfoBox;