import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import PinInput from 'react-pin-input';
import TextareaAutosize from 'react-textarea-autosize';
import './vesta-input.css';


class VestaInput extends Component {
    static defaultProps = {
        type: 'text',
        onValueChange: (value) => { },
        value: ''
    };

    state = {
        value: this.props.value
    };

    inputRef = React.createRef();

    changeValue = (value) => {
        this.setState((prev) => {
            if (this.props.onValueChange) {
                this.props.onValueChange(value);
            }
            return {
                value: value
            };
        });
    };

    onNumberValueChange = (val) => {
        this.changeValue(val.value);
    };

    onTextValueChange = (e) => {
        this.changeValue(e.target.value);
    };

    onPinChange = (value, index) => {
        this.changeValue(value);
    }

    clearValue = () => {
        this.changeValue('');
        this.inputRef.current.focus();
    };

    componentDidMount() {
        if (this.inputRef && this.inputRef.current && this.props.focused) {
            this.inputRef.current.focus();
        }
    }

    render() {
        const { type, placeHolder, inputClassName, multiline } = this.props;

        let clearButton = null;
        if (this.state.value) {
            clearButton = <div className='clear-button' onClick={this.clearValue} />;
        }

        const numberFormatInput = (
            <NumberFormat
                getInputRef={this.inputRef}
                className={inputClassName}
                format="+### ## ### ## ##"
                mask="_"
                placeholder={placeHolder}
                onValueChange={this.onNumberValueChange}
                value={this.state.value}
            />
        );

        const textInput = !multiline ? (
            <input
                type="text"
                className={inputClassName}
                placeholder={placeHolder}
                onChange={this.onTextValueChange}
                ref={this.inputRef}
                value={this.state.value} />
        ) : (
                <TextareaAutosize
                    className={inputClassName}
                    placeholder={placeHolder}
                    onChange={this.onTextValueChange}
                    inputRef={this.inputRef}
                    value={this.state.value} />
            );

        const pinInputStyle =
        {
            input: {
                color: inputClassName === 'error' ? '#EE3A36' : 'black',
                padding: 0,
                margin: '0 5px',
                textAlign: 'center',
                border: 0,
                borderBottom: '1px solid black',
                background: 'transparent',
                width: '50px',
                height: '50px',
                outline: 'none',
            },
            inputFocus: {
                outline: 'none',
                boxShadow: 'none',
            },
            general: {
                textAlign: 'center',
                overflow: 'auto',
                marginTop: '15px',
                width: '100%',
                border: '1'
            }
        };
        const pinInput = (
            <PinInput
                style={pinInputStyle.general}
                inputStyle={pinInputStyle.input}
                length={4}
                initialValue={this.state.value}
                onChange={this.onPinChange}
            />
        );

        let inputElement = null;
        let className = 'vesta-input';
        switch (type) {
            case 'pin':
                inputElement = pinInput;
                className = 'vesta-input noborder';
                clearButton = null;
                break;
            case 'phone':
                inputElement = numberFormatInput;
                break;
            default:
                inputElement = textInput;
                break;
        }

        return (
            <div className={className}>
                <div className="input-element" >
                    {inputElement}
                </div>
                {clearButton}
            </div>
        );
    };
};

export default VestaInput;