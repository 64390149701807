import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import HomePage from '../pages/home-page';
import SetLocation from './set-location';
import SignInPage from '../pages/sign-in-page';
import RegisterPage from '../pages/register-page';
import ProductsPage from '../pages/products-page';
import VerificationPage from '../pages/verification-page';
import AccountPage from '../pages/account-page';
import { withRouter } from 'react-router-dom'
import { userSignedIn, locationSelected, locationsLoaded, userSignedOut } from '../../actions';
import { Cookies } from 'react-cookie';
import withApiService from '../hoc/with-api-service'
import Spinner from '../spinner';
import OrdersPage from '../pages/orders-page';
import PaymentSuccessPage from '../pages/payment-success-page';
import PaymentFailPage from '../pages/payment-fail-page';
import FeedbackPage from '../pages/feedback-page';
import ReactGA from 'react-ga';
import AdminPage from '../admin';

class VestaApp extends Component {

    state = {
        loading: true,
        gaInitialized: false,
        customerSessionCheck: false
    }

    sendSignInDataToGA = (signInData) => {
        ReactGA.set({
            userId: signInData.customerData.customerId,
        });

        ReactGA.event({
            category: 'User',
            action: 'SignedIn',
            label: signInData.customerData.customerId.toString()
        });
    }

    onSignIn = (signInData) => {
        const { userSignedIn, history } = this.props;
        new Cookies().set('vesta-session', signInData.sessionGuid, {
            path: '/',
            expires: new Date(new Date().setMonth(new Date().getMonth() + 1))
        });
        userSignedIn(signInData.customerData, signInData.sessionGuid);
        this.sendSignInDataToGA(signInData);

        history.push('/');
    };

    checkCookieSession = () => {
        const { customer, apiService, userSignedIn } = this.props;
        if (customer) {
            return;
        }
        const sessionGuid = new Cookies().get('vesta-session');
        if (sessionGuid) {
            apiService.signWithSession(sessionGuid)
                .then((signInData) => {
                    if (signInData.success) {
                        userSignedIn(signInData.customerData, sessionGuid);
                        this.sendSignInDataToGA(signInData);
                    } else {
                        userSignedOut();
                    }
                    this.setState({
                        loading: false
                    })
                });
        } else {
            this.setState({
                loading: false
            });
        }
    };

    setLocationIntoCookie = (value) => {
        new Cookies().set('vesta-location', value, {
            path: '/',
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        });
    };

    setLocation = (location) => {
        const { locationSelected } = this.props;
        this.setLocationIntoCookie(location.code);
        locationSelected(location);
    };

    checkLocationCookie = (locations) => {
        const cookies = new Cookies();
        const cookieLocation = cookies.get('vesta-location');
        if (cookieLocation) {
            const location = locations.find(l => l.code.toUpperCase() === cookieLocation.toUpperCase());
            this.setLocation(location ?? locations[0]);
        } else {
            const location = locations[0];
            this.setLocation(location);
        }
    };

    componentDidUpdate() {
        const { apiService, locationsLoaded, vestaLocation, history } = this.props;
        if (!vestaLocation) {
            apiService.getLocations()
                .then(res => {
                    locationsLoaded(res);
                    this.checkLocationCookie(res);
                });
        }

        if (history.location.pathname.indexOf('/admin') !== 0) {
            document.getElementById('root').style['max-width'] = '400px';
        } else {
            document.getElementById('root').style['max-width'] = 'none';
            document.getElementById('root').style['width'] = 'auto';
        }
    }

    componentDidMount() {
        const { history } = this.props;
        this.checkCookieSession();

        //GA initialization
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
            siteSpeedSampleRate: 30
        });

        ReactGA.pageview(window.location.pathname + window.location.search);
        history.listen((location, action) => {
            ReactGA.pageview(location.pathname + location.search)
        });
    };

    render() {
        const { customer } = this.props;

        if (this.state.loading) {
            return <Spinner />
        }

        const HomeView = () => {
            return customer
                ? <Redirect to='/products' />
                : <HomePage onSignIn={this.onSignIn} />
        }

        return (
            <Switch>
                <Route path="/" exact>
                    <HomeView />
                </Route>
                <Route path="/location/:locationCode" exact>
                    <SetLocation setLocationIntoCookie={this.setLocationIntoCookie} />
                </Route>
                <Route path="/products" exact>
                    <ProductsPage setLocation={this.setLocation} />
                </Route>
                <Route path="/paymentinfo" exact>
                    <ProductsPage setLocation={this.setLocation} />
                </Route>
                <Route path="/deliveryinfo" exact>
                    <ProductsPage setLocation={this.setLocation} />
                </Route>
                <Route path="/agreement" exact>
                    <ProductsPage setLocation={this.setLocation} />
                </Route>
                <Route path="/contacts" exact>
                    <ProductsPage setLocation={this.setLocation} />
                </Route>
                <Route path="/selectlocation" exact>
                    <ProductsPage setLocation={this.setLocation} />
                </Route>
                <Route path="/account" exact>
                    <AccountPage />
                </Route>
                <Route path="/orders" exact>
                    <OrdersPage />
                </Route>
                <Route path="/paymentstatussuccess" exact>
                    <PaymentSuccessPage />
                </Route>
                <Route path="/paymentstatusfailed" exact>
                    <PaymentFailPage />
                </Route>
                <Route path="/signin" exact>
                    <SignInPage onSignIn={this.onSignIn} />
                </Route>
                <Route path="/register" exact>
                    <RegisterPage />
                </Route>
                <Route path="/verify" exact>
                    <VerificationPage onSignIn={this.onSignIn} />
                </Route>
                <Route path="/verifysignin" exact>
                    <VerificationPage onSignIn={this.onSignIn} />
                </Route>
                <Route path="/feedback" exact>
                    <FeedbackPage />
                </Route>
                <Route path="/feedbackdirect" exact>
                    <FeedbackPage direct />
                </Route>
                <Route path="/admin">
                    <AdminPage />
                </Route>
                <Route>
                    <Redirect to='/' />
                </Route>
            </Switch>

        );
    }
};
const mapStateToProps = ({ customer, vestaLocation, vestaLocations }) => {
    return { customer, vestaLocation, vestaLocations }
};

export default connect(mapStateToProps, { userSignedIn, locationsLoaded, locationSelected })(withApiService(withRouter(VestaApp)));