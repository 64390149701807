import OrderState from '../consts/order-state';
import { changeItemCount, squashItemsForOrderedProduct, toggleAddition, mergeSelectedProductWithOder, createSelectedProduct } from './common';

const cartItemCountChanged = (state, productItem) => {
    const { orderState } = state;
    const selectedItemEdit = orderState === OrderState.add || orderState === OrderState.edititem;
    const order = 
        changeItemCount(
            selectedItemEdit
                ? state.selectedProduct
                : state.orderEdit, productItem);
    return {
        ...state,
        orderEdit: selectedItemEdit ? state.orderEdit : order,
        selectedProduct: selectedItemEdit ? order : state.selectedProduct,
    }
};

const cartAdditionChanged = (state, payload) => {
    const { orderState } = state;
    const { addition, item } = payload;
    const selectedItemEdit = orderState === OrderState.add || orderState === OrderState.edititem;
    const orderedProduct = squashItemsForOrderedProduct(
        toggleAddition(selectedItemEdit ? state.selectedProduct : state.order, item, addition));
    if (selectedItemEdit) {
        return {
            ...state,
            selectedProduct: orderedProduct,
        };
    } else {
        return {
            ...state,
            order: orderedProduct,
        };
    }
}

const addToOrderRequested = (state, product, productItem) => {
    if (!product || !productItem) {
        return;
    }
    const newState = {
        ...state,
        orderState: OrderState.add,
        selectedProduct: squashItemsForOrderedProduct(createSelectedProduct(product, { ...productItem, count: 1 })),
    };
    return newState;
};

const addSelectedProductToOrder = (state) => {
    const newOrder = mergeSelectedProductWithOder(state.order, state.selectedProduct);
    return {
        ...state,
        orderState: newOrder.orderItems.length > 0 ? OrderState.collapsed : OrderState.empty,
        selectedProduct: null,
        order: newOrder,
    }
};

export {
    cartItemCountChanged,
    addSelectedProductToOrder,
    addToOrderRequested,
    cartAdditionChanged
};