import OrderState from '../consts/order-state';
import i18n from '../i18n';
import { Cookies } from 'react-cookie';

const getPropLang = (lang) => {
    return lang === 'ua' ? 'Ua' : 'En'
}

const generateLangDict = (lang) => {
    var i18nLabels = {};
    Object.keys(i18n).forEach(key => {
        i18nLabels[key] = i18n[key][lang]
    });
    i18nLabels.prop = (instance, prop) => {
        return instance[`${prop}${getPropLang(lang)}`];
    };
    return i18nLabels;
}

const initialLang = new Cookies().get('vesta-lang') ?? 'ua';

const initialState = () => {
    return {
        lang: initialLang,
        i18n: generateLangDict(initialLang),
        productGroups: [],
        signInData: {
            phoneNumber: '380'
        },
        specials: [],
        customer: null,
        sessionGuid: null,
        orderState: OrderState.empty,
        order: {
            orderItems: []
        },
        selectedProduct: null
    }
};

export { initialState, generateLangDict };