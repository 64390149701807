import React from 'react';

const PaymentInfo = () => {
    return (
        <div className='payment-info'>
            <div className='par'>
                <b>Оплата</b> карткою в мобільному додатку або готівкою за вказаною адресою.
            </div>
            <div className='par'>
                Орієнтовний час очікування замовлення готових страв та напоїв <b>до 10 хв зі складу</b> або по домовленості з Покупцем.
            </div>
            <div className='par'>
                Мінімальна сума замовлення відповідає сумі оплаченого замовлення одного товару.
            </div>
            <div className='par'>
                Самовивіз доступний за адресою, вказаною в мобільному додатку.
            </div>
        </div>
    )
}

export default PaymentInfo;