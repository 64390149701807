import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import withApiService from '../../hoc/with-api-service';
import Header from '../../header'
import Specials from '../../specials'
import ProductGroup from '../../product-group'
import CartContanier from '../../cart';
import { productGroupsLoaded, addToOrderRequested, orderUpdated, editOrderItemRequested } from '../../../actions';
import Spinner from '../../spinner';
import FeedbackBox from '../../feedback/feedback-box';
import ReactGA from 'react-ga';
import OrderState from '../../../consts/order-state';
import About from '../../about';
import './products-page.css';
import LocationSelector from '../../location-selector';

class ProductsPage extends Component {

    state = {
        loading: false
    }

    orderItemClick = (product, item) => {
        const { editOrderItemRequested } = this.props;
        editOrderItemRequested(product, item);
        ReactGA.event({
            category: 'Order',
            action: 'Edit item click',
            label: item.nameUa
        });
    };

    onProductClick = (product, exactItem) => {
        const { addToOrderRequested } = this.props;
        addToOrderRequested(product, exactItem);
        ReactGA.event({
            category: 'Order',
            action: 'Add item click',
            label: exactItem.nameUa
        });
    }; 
  
    selectLocation = () => {
        this.props.history.push('/selectlocation');
    };

    componentDidMount() {
        const { apiService, productGroupsLoaded, orderUpdated, sessionGuid } = this.props;
        apiService.getProductGroups()
            .then(res => {
                productGroupsLoaded(res);
                apiService.getLastTempOrder(sessionGuid)
                    .then(result => {
                        if (result.success && result.order) {
                            orderUpdated(result.order);
                        }
                    })
            });
    }

    setLoading = (loading) => {
        this.setState({
            loading: loading
        })
    }

    render() {
        const { customer, productGroups, i18n, order, history, orderState, vestaLocation, vestaLocations, setLocation } = this.props;

        if (!customer) {
            return <Redirect to='/' />
        }

        const productGroupCards = productGroups.filter(g => !g.isSpecial).map((productGroup) => {
            return (
                <ProductGroup
                    i18n={i18n}
                    productGroup={productGroup}
                    key={productGroup.productGroupId}
                    itemClick={this.onProductClick}
                    orderItemClick={this.orderItemClick}
                    order={order}
                />);
        });
        const locationView = vestaLocation ? (
            <div className='location' onClick={this.selectLocation}>
                <div className='pin'></div>
                <div className='address'>
                    {i18n.prop(vestaLocation, 'address')}
                </div>
                <div className='arrow-disabled'></div>
            </div>
        ) : null;
        document.body.style.overflow = (orderState > OrderState.collapsed) || (history.location.pathname !== '/products')
            ? 'hidden' : 'unset';
        return (

            <div className='products-page'>
                {this.state.loading ? <Spinner onTop /> : null}
                <Header
                    i18n={i18n}
                    customer={customer}
                />
                {locationView}
                <Specials i18n={i18n} itemClick={this.onProductClick} orderItemClick={this.orderItemClick} />
                <div className='products-list'>
                    {productGroups.length > 0 ? productGroupCards : <Spinner />}
                </div>
                <FeedbackBox
                    image={'star'}
                    title={i18n.shareYourTought}
                    subtitle1={i18n.rateOurApp}
                    subtitle2={i18n.andHelpBecomeBetter}
                    button1Label={i18n.rateApp}
                    button1Click={() => history.push('/feedbackdirect')} />
                <About
                    i18n={i18n}
                    history={history}
                />
                {history.location.pathname === '/selectlocation' && vestaLocations
                    ? <LocationSelector
                        i18n={i18n}
                        vestaLocation={vestaLocation}
                        vestaLocations={vestaLocations}
                        history={history}
                        locationSelected={setLocation} />
                    : null}
                <CartContanier
                    setLoading={this.setLoading}
                    selectLocation={this.selectLocation}
                    setLocation={setLocation}
                    vestaLocation={vestaLocation} />
            </div>
        );
    };
};

const mapStateToProps = ({ customer, productGroups, i18n, order, sessionGuid, orderState, vestaLocations, vestaLocation }) => {
    return { customer, productGroups, i18n, order, sessionGuid, orderState, vestaLocations, vestaLocation }
};


export default connect(mapStateToProps, {
    productGroupsLoaded,
    addToOrderRequested,
    orderUpdated,
    editOrderItemRequested
})(withApiService(withRouter(ProductsPage)));