import React from 'react'
import { connect } from 'react-redux';
import { withRouter, Link, Switch, Route } from 'react-router-dom';
import withApiService from '../hoc/with-api-service';
import Spinner from '../spinner';
import ManageBonus from './manage-bonus';
import ManageRoles from './manage-roles';
import ManageLocations from './manage-locations';
import ManageMenu from './manage-menu';
import './admin-page.css'


class AdminPage extends React.Component {

    state = {
        features: null,
        selectedFeature: null,
        selectedFeatureId: null
    }

    componentDidMount() {
        const { history, apiService, sessionGuid } = this.props;
        const administrationConst = 'Administration';

        apiService.getFeatures(sessionGuid)
            .then(res => {
                if (res && res.length > 0 && res.filter(f => f.name === administrationConst).length === 1) {
                    const features = res.filter(f => f.name !== administrationConst);
                    this.setState({
                        features: features
                    })
                } else {
                    history.push('/');
                }
            })
            .catch((e) => {
                history.push('/');
            });


    }

    renderFeature(feature) {
        return (
            <div className='feature-container'>
                <Link to='/admin'>До адміністрування</Link>
                <div className='feature-title'>{feature.displayName}</div>
                <hr />
                {this.renderFeatureComponent(feature)}
            </div>
        )
    };

    renderFeatureComponent(feature) {
        const { apiService, history } = this.props;
        switch (feature.name) {
            case 'ManageBonus':
                return <ManageBonus apiService={apiService} history={history} />;
            case 'ManageRoles':
                return <ManageRoles apiService={apiService} history={history} />;
            case 'ManageLocations':
                return <ManageLocations apiService={apiService} history={history} />;
            case 'ManageMenu':
                return <ManageMenu apiService={apiService} history={history} />;
            default:
                return null;
        }
    };
    render() {
        const { features } = this.state;
        if (!features) {
            return <Spinner />;
        }

        const featureLinks = features.map(f => (
            <div className='feature-link' key={f.name}>
                <Link to={`/admin/${f.name.toLowerCase()}`} >{f.displayName}</Link>
            </div>));
        const routes = features.map(f => (
            <Route key={f} path={`/admin/${f.name.toLowerCase()}`}
                render={() => this.renderFeature(f)} />)
        );

        return (
                <Switch>
                    {routes}
                    <Route>
                        <div className="admin-page">
                            <div className="header">Kredens адмін</div>
                            <Link to='/'>До програми</Link>
                            <hr />
                            {featureLinks}
                        </div>
                    </Route>
                </Switch>

        );
    }
};

const mapStateToProps = ({ customer, sessionGuid }) => {
    return { customer, sessionGuid }
};


export default connect(mapStateToProps)(withApiService(withRouter(AdminPage)));