import React, { Component } from 'react';
import { connect } from 'react-redux';
import VestaButton from '../../vesta-button';
import VestaInput from '../../vesta-input';
import { withRouter, Redirect } from 'react-router-dom'
import withApiService from '../../hoc/with-api-service'
import Spinner from '../../spinner';
import './verification-page.css';

class VerificationPage extends Component {

    state = {
        registerDisabled: true,
        invalidCode: false,
        code: '',
        loading: false
    };

    onCodeChange = (value) => {
        this.setState({
            code: value,
            registerDisabled: value.length < 4
        });
    };

    registerButtonClick = () => {
        const { apiService, signInData, onSignIn } = this.props;
        this.setState({
            loading: true
        });
        apiService.register({
            phoneNumber: signInData.phoneNumber,
            name: signInData.userName,
            verificationCode: this.state.code
        })
            .then((res) => {
                this.setState({
                    loading: false
                });
                if (res.success) {
                    onSignIn(res);
                } else {
                    this.setState({
                        invalidCode: true
                    });
                }
            });
    };

    registerAgainClick = () => {
        const { history } = this.props;
        const isSignIn = history.location.pathname === '/verifysignin';

        if (isSignIn) {
            history.push('/signin');
        } else {
            history.push('/register');
        }
    };

    render() {
        if (this.state.loading) {
            return <Spinner />
        }

        const { i18n, signInData, history, customer } = this.props;
        if (!signInData.phoneNumber) {
            return <Redirect to='/register' />
        }

        if (customer) {
            return <Redirect to='/products' />
        }

        const errorDescription = this.state.invalidCode ?
            (
                <div>
                    <div className='error'>
                        <span>{i18n.invalidCode}</span>
                    </div>
                    <div className='registerLink'>
                        <span onClick={this.registerAgainClick}>{i18n.sendSmsToAnotherNumber}</span>
                    </div>
                </div>
            )
            : (
                <div className='info'>
                    <div>Не приходить смс?</div>
                    <div>Телефонуй на номер: +380(67)8687133</div>
                </div>
            );
        const isSignIn = history.location.pathname === '/verifysignin';
        return (

            <div className='verification-page signin-bg'>
                <div className='logo kredens-logo' />
                <div className='input-box'>
                    <div className='header'>
                        <span>{isSignIn ? 'ВХІД' : i18n.registration}</span>
                    </div>
                    <div className='description'>
                        <span>{i18n.enterCode}</span>
                    </div>
                    <div className='code'>
                        <VestaInput
                            inputClassName={this.state.invalidCode ? 'error' : null}
                            type='pin'
                            onValueChange={this.onCodeChange} />
                        {errorDescription}
                    </div>
                    <div className='submit'>
                        <VestaButton
                            label={isSignIn ? 'УВІЙТИ' : i18n.register}
                            image={this.state.registerDisabled ? 'arrow-disabled' : 'arrow-white'}
                            disabled={this.state.registerDisabled}
                            onButtonClick={this.registerButtonClick}
                            theme='black' />
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ signInData, i18n, customer }) => {
    return { signInData, i18n, customer }
};

export default connect(mapStateToProps)(withApiService(withRouter(VerificationPage)));