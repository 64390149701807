import React, { useState, Fragment } from 'react';
import VestaButton from '../../vesta-button';
import CartHeader from './cart-header';
import LocationSelector from './../../location-selector';
import withApiService from '../../hoc/with-api-service'
import { itemsAreEqual, getUniqueItemKey } from '../../../reducers/common'
import { getOrderItemFullName, getOrderItemFullPrice } from '../../../common'
import { calculateTotal } from '../../../common'
import './payment-select.css';

const PaymentSelect = ({ productGroups, isClosed, userSignedOut, selectLocation, vestaLocation, vestaLocations, setLocation, setLoading, order, sessionGuid, payClick, editClick, i18n, closeClick, customer, lang, apiService }) => {

    const [payHandler, setPayHandler] = useState(null);

    const onlinePayClick = () => {
        if (isClosed()) {
            return;
        }

        setLoading(true);
        apiService.saveOrder(order, sessionGuid)
            .then(result => {
                if (result.success) {
                    const form = document.getElementById('pay-form');
                    form["order_id"].value = `${result.order.orderId}-${Date.now()}`;
                    const formData = new FormData(form);
                    const sortedKeys = Array.from(formData.keys()).filter(i => i != 'signature').sort();
                    var orderValues = sortedKeys.map(i => formData.get(i)).join('|');
                   
                    apiService.signOrder(result.order.orderId, sessionGuid, orderValues)
                        .then(res => {
                            window.setTimeout(() => { setLoading(false); }, 2000);
                            form["signature"].value = res.signature;  
                            form["merchant_id"].value = res.merchantId;                           
                            form.submit();
                        });
                } else if (result.errorCode === 2) {
                    userSignedOut();
                }
            })
    };

    const products = order.orderItems.map(item => {

        const additionsList = item.orderItemAdditions.map(a => i18n.prop(a, 'name')).join(', ');
        const separator = itemsAreEqual(item, order.orderItems[0])
            ? <div className="separator"></div>
            : null;
        return (
            <div key={getUniqueItemKey(item)}>
                {separator}
                <div className='product'>
                    <div className='name'>
                        {i18n.prop(item, 'name')}
                    </div>
                    <div className='main-data'>
                        <div className='size'>
                            {i18n.prop(item, 'size')}
                        </div>
                        <div className='count'>
                            x {item.count}
                        </div>
                        <div className='price'>
                            {getOrderItemFullPrice(item) * item.count} <span className='uah'>₴</span>
                        </div>
                    </div>
                    <div className='description'>
                        <div className='additions'>{additionsList}</div>
                    </div>
                    <div className='separator'></div>
                </div>
            </div>
        )
    });

    const orderTotal = calculateTotal(order);
    const overflowClass = order.orderItems.length > 3 ? 'overflow' : '';
    const orderItems = order.orderItems;
    const noBonusProducItems = productGroups
        .filter(g => g.noBonus).map(g => g.products)
        .reduce((a, b) => a.concat(b), []).map(p => p.productItems)
        .reduce((a, b) => a.concat(b), []);
    const orderContainsNoBonus = orderItems.some(o => noBonusProducItems.some( pi => pi.productItemId === o.productItemId) );
    const orderAllItemsCount = order.orderItems.reduce((t, i) => t + i.count, 0);
    const orderDesc = orderItems.map(item => `${getOrderItemFullName(item)} - ${getOrderItemFullPrice(item)}грн; `).join('');
    const locationView = vestaLocation ? (
        <div className='location-payment' onClick={selectLocation}>
            <div className='pin'></div>
            <div>
                <div className='address-label'>Адреса:</div>
                <div className='address'>
                    {i18n.prop(vestaLocation, 'address')}
                </div>
            </div>
            <div className='arrow-disabled'></div>
        </div>
    ) : null;

    const form = (withButton) => {
        return (  
        <form method="post" action="https://pay.fondy.eu/api/checkout/redirect/" id='pay-form'>
            <input name="order_id" type='hidden' />
            <input name="merchant_id" value="replace_merchant_id" type='hidden' />
            <input name="order_desc" value={orderDesc} type='hidden' />
            <input name="signature" type='hidden' />
            <input name="amount" value={orderTotal * 100} type='hidden' />
            <input name="currency" value="UAH" type='hidden' />
            <input name="lang" value={lang == 'ua' ? 'uk' : 'en'} type='hidden' />           
            <input name="response_url" value={`${process.env.REACT_APP_PAYMENT_REDIRECT_URL}`} type='hidden' />
            {withButton ? <VestaButton
                className='apple'
                label={i18n.payOnline}
                onButtonClick={() => setPayHandler({ name: i18n.payOnline, handler: () => onlinePayClick() })}
                image={'arrow-white'}
            /> : null}
        </form>
        );
    };

    if (payHandler) {
        return (<Fragment>
            <LocationSelector
                i18n={i18n}
                vestaLocation={vestaLocation}
                vestaLocations={vestaLocations}
                locationSelected={setLocation}
                payHandler={payHandler}
                backClick={() => setPayHandler(null)} />
            {form()}
        </Fragment>);
    }

    return (

        <div className='payment-select'>
            <div className='overlay' onClick={closeClick}></div>
            <CartHeader title={i18n.yourOrder} closeClick={closeClick} />
            {locationView}
            <div className={`products ${overflowClass}`}>
                {products}
                <div className='bottom'>
                    <div className='edit-link' onClick={editClick}>{i18n.edit}</div>
                    <div className='total-label'>{i18n.totalLabel}</div>
                    <div className='total'>{orderTotal}</div>
                    <div className='uah'>₴</div>
                </div>
            </div>
            <div className='footer'>
                {form(true)}
                {process.env.REACT_APP_PAY_WITH_CASH === "1"
                    ? <VestaButton
                        label={i18n.payWithCash}
                        onButtonClick={() => setPayHandler({ name: i18n.payWithCash, handler: () => payClick(3) })}
                        image={'arrow-white'}
                    /> : null}
                <VestaButton
                    disabled={orderContainsNoBonus || (customer.bonuses / 10 < orderAllItemsCount)}
                    label={i18n.payWithBonuses}
                    onButtonClick={() => setPayHandler({ name: i18n.payWithBonuses, handler: () => payClick(4) })}
                    image={'arrow-white'}
                />

            </div>
        </div >
    );
};

export default withApiService(PaymentSelect);