import actionTypes from '../consts/action-types';
import OrderState from '../consts/order-state';
import { cartItemCountChanged, addSelectedProductToOrder, addToOrderRequested, cartAdditionChanged } from './cart-reducers'; import { initialState, generateLangDict } from './initial-state';
import { squashItemsForOrderedProduct, createSelectedProduct, cloneOrder, itemsAreEqual } from './common';

const reducer = (state = initialState(), action) => {
    switch (action.type) {
        case actionTypes.languageChanged:
            const lang = action.payload;
            const i18nLabels = generateLangDict(lang);
            return {
                ...state,
                i18n: i18nLabels,
                lang: lang,
                propLang: lang === 'ua' ? 'Ua' : 'En'
            };
        case actionTypes.userSignedIn:
            const { customerData, sessionGuid } = action.payload;
            if (customerData.isGuest) {
                customerData.name = state.i18n.guest
            }
            return {
                ...state,
                customer: customerData,
                sessionGuid: sessionGuid
            }

        case actionTypes.userSignedOut:
            return initialState();
        case actionTypes.productGroupsLoaded:
            return {
                ...state,
                productGroups: action.payload
            }
        case actionTypes.signInDataChanged:
            return {
                ...state,
                signInData: action.payload
            }
        case actionTypes.specialsLoaded:
            return {
                ...state,
                specials: action.payload
            }
        case actionTypes.addToOrderRequested:
            const { product, productItem } = action.payload;
            return addToOrderRequested(state, product, productItem);
        case actionTypes.addToOrderCanceled:
            return {
                ...state,
                orderState: state.order.orderItems.length > 0 ? OrderState.collapsed : OrderState.empty,
                selectedProduct: null
            }
        case actionTypes.incOrderItem:
        case actionTypes.decOrderItem:
            return cartItemCountChanged(state, action.payload);
        case actionTypes.removeOrderItem:
            if (!state.orderEdit) {
                return;
            }
            return {
                ...state,
                orderEdit: {
                    ...state.orderEdit,
                    orderItems: state.orderEdit.orderItems.filter(i => !itemsAreEqual(i, action.payload))
                }
            }
        case actionTypes.addSelectedProductToOrderRequested:
            return addSelectedProductToOrder(state, action.payload);
        case actionTypes.changeAddition:
            return cartAdditionChanged(state, action.payload);
        case actionTypes.editOrderRequested:
            return {
                ...state,
                orderEdit: cloneOrder(state.order),
                orderState: OrderState.edit,
            };
        case actionTypes.editOrderDismissed:
            return {
                ...state,
                orderEdit: null,
                orderState: state.order.orderItems.length > 0 ? OrderState.collapsed : OrderState.empty
            }
        case actionTypes.editOrderConfirmed:
            return {
                ...state,
                order: { ...squashItemsForOrderedProduct(cloneOrder(state.orderEdit), true) },
                orderEdit: null,
                orderState: state.orderEdit.orderItems.length > 0 ? OrderState.collapsed : OrderState.empty,
            }
        case actionTypes.orderPaymentRequested:
            return {
                ...state,
                order: {
                    ...state.order,
                    location: state.vestaLocation ? state.vestaLocation : null
                },
                orderState: OrderState.pay,
            }
        case actionTypes.orderPaid:
            return {
                ...state,
                order: { orderItems: [] },
                selectedProduct: null,
                orderState: OrderState.empty,
            }
        case actionTypes.customerDataChanged:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    ...action.payload
                }
            }
        case actionTypes.orderUpdated:
            if (!action.payload) {
                return {
                    ...state,
                    order: initialState().order,
                    orderHasChanged: false,
                    selectedProduct: null,
                    orderState: OrderState.empty
                }
            }
            const updateOrder = {
                ...action.payload
            };
            updateOrder.orderItems.sort((i1, i2) => i1.orderItemId - i2.orderItemId);
            return {
                ...state,
                orderState: state.orderState === OrderState.empty
                    ? updateOrder.orderItems.length > 0
                        ? OrderState.collapsed
                        : OrderState.empty
                    : state.orderState,

                selectedProduct: null,
                orderHasChanged: false,
                order: {
                    ...updateOrder,
                    location: updateOrder.location ?? state.vestaLocation
                }
            }
        case actionTypes.orderHasChanged:
            return {
                ...state,
                hasOrderChanged: action.payload
            }
        case actionTypes.editOrderItemRequested:
            const { selectedProduct, selectedOrderItem } = action.payload;

            return {
                ...state,
                selectedProduct: createSelectedProduct(selectedProduct, selectedOrderItem),
                orderState: OrderState.edititem
            }
        case actionTypes.editOrderItemDismissed:
            return {
                ...state,
                selectedProduct: null,
                orderState: OrderState.collapsed
            }
        case actionTypes.editOrderItemConfirmed:
            if (!state.selectedProduct && state.itemToRemove) {
                return {
                    ...state,
                    orderState: OrderState.collapsed,
                    order: {
                        ...state.order,
                        orderItems: state.order.orderItems.filter(item =>
                            item.orderItemId !== state.itemToRemove.orderItemId)
                    },
                    itemToRemove: null
                }
            }

            const editedItem = state.selectedProduct.orderItems[0];
            return {
                ...state,
                order: squashItemsForOrderedProduct({
                    ...state.order,
                    orderItems: state.order.orderItems.map(item =>
                        item.orderItemId === editedItem.orderItemId
                            ? editedItem
                            : item)
                }, true),
                selectedProduct: null,
                orderState: OrderState.collapsed
            }
        case actionTypes.editOrderItemRemoveConfirmed:
            return {
                ...state,
                selectedProduct: null,
                itemToRemove: state.selectedProduct.orderItems[0],
            }
        case actionTypes.locationsLoaded:
            return {
                ...state,
                vestaLocations: action.payload.filter(l => l.isActive)
            }
        case actionTypes.locationSelected:
            const location = action.payload;
            const newState = {
                ...state,
                vestaLocation: location,
            }
            if (newState.order) {
               newState.order.location = location
            }
            return newState;
        default:
            return state;

    };
};

export default reducer;