import React, { useState, useEffect } from 'react';
import VestaButton from '../vesta-button';
import CartHeader from '../cart/cart-views/cart-header';
import './location-selector.css';


const LocationSelector = ({ i18n, vestaLocations, vestaLocation, history, locationSelected, payHandler, backClick }) => {
    const locationsView = vestaLocations.map(location => {
        const selected = vestaLocation && vestaLocation.locationId === location.locationId ? 'selected' : '';
        return (
            <div className={`location-item ${selected}`} id={`location_${location.locationId}`} key={location.locationId} onClick={() => locationSelected(location)}>
                <div className='radio'></div>
                <div className='address'>
                    {i18n.prop(location, 'address')}
                </div>
                <div className='distance'></div>
            </div>);
    });

    const closeClick = () => {
        history.push('/products');
    };

    const payClick = () => {
        payHandler.handler();
    };

    const [scrolled, setScrolled] = useState(false);
    const scrollToSelected = () => {
        const selectedLocationElement = document.getElementById(vestaLocation ? `location_${vestaLocation.locationId}` : null);
        if (selectedLocationElement && !scrolled) {
            setScrolled(true);
            selectedLocationElement.scrollIntoView();
        }
    }

    useEffect(() => {
        // Оновлюємо заголовок документа, використовуючи API браузера
        if(!scrolled){
            scrollToSelected();
        }
      });
   
    return (
        <div className='location-selector'>
            <div className='overlay'></div>
            <CartHeader title='АДРЕСИ KREDENS CAFE' closeClick={payHandler ? backClick : closeClick}></CartHeader>
            <div className='locations-container'>
                <div className='locations'>
                    {locationsView}
                </div>
            </div>
            <div className={`footer${payHandler ? ' pay' : ''}`}>
                <VestaButton
                    label={payHandler ? payHandler.name : i18n.backToMenu}
                    onButtonClick={payHandler ? payClick : closeClick}
                    preImage={payHandler ? null : 'arrow-left'}
                    image={payHandler ? 'arrow-white' : null}
                />
                {payHandler ? <VestaButton
                    label={'ПОВЕРНУТИСЬ'}
                    onButtonClick={backClick}
                    preImage={'arrow-left'}
                /> : null}
            </div>
        </div>
    );
};

export default LocationSelector;