import React from 'react';
import { connect } from 'react-redux';
import './home-page.css';
import VestaButton from '../../vesta-button';
import { withRouter } from 'react-router-dom'
import { languageChanged } from '../../../actions';
import { Cookies } from 'react-cookie';
import ReactGA from 'react-ga';

const HomePage = ({ lang, i18n, history, languageChanged }) => {
    const onLangClick = () => {
        const newLang = lang === 'ua' ? 'en' : 'ua';
        new Cookies().set('vesta-lang', newLang);
        languageChanged(newLang);
        ReactGA.event({
            category: 'User',
            action: 'Lang changed',
            label: newLang
          });
    };

    const onSignInClick = () => {
        ReactGA.event({
            category: 'Navigation',
            action: 'SignIn clicked'
          });
        history.push('/signin')
    };

    const onRegisterClick = () => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Register clicked'
          });
        history.push('/register')
    };

    return (
        <div className='home-page'>
            <div className='home-page-top account-bg'>
                <div className='header'>
                    <div className='home-page-lang'>
                        <span onClick={onLangClick}>{i18n.langHome}</span>
                    </div>
                    <div className='home-page-logo kredens-logo' />
                </div>
                <div className="home-page-signin">
                    <div className="home-page-signin-user">
                        <VestaButton
                            label={i18n.registered}
                            onButtonClick={onSignInClick} />
                    </div>
                </div>
            </div>
            <div className='home-page-bottom'>
                <div className="home-page-signin-register">
                    <VestaButton
                        label={i18n.register}
                        onButtonClick={onRegisterClick} />
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = ({ lang, i18n }) => {
    return { lang, i18n }
};

export default connect(mapStateToProps, { languageChanged })(withRouter(HomePage));