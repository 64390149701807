import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import withApiService from '../../hoc/with-api-service';
import { withRouter } from 'react-router-dom'
import { userSignedOut } from '../../../actions';
import VestaButton from '../../vesta-button';
import InfoBox from '../../info-box';
import { formatPhoneNumber } from '../../../common'
import Spinner from '../../spinner';
import { Cookies } from 'react-cookie';
import './account-page.css';

class AccountPage extends React.Component {

    state = {
        lastOrder: null,
        noOrder: true,
        isAdmin: false
    }

    signOut = () => {
        const { userSignedOut, history } = this.props;
        new Cookies().remove('vesta-session');
        userSignedOut();
        history.push('/');
    };

    componentDidMount() {
        const { apiService, sessionGuid } = this.props;   
        const administrationConst = 'Administration';
        apiService
            .getLastOrder(sessionGuid)
            .then(result => {
                if (result.success && result.order) {
                    this.setState({ lastOrder: result.order });
                } else {
                    this.setState({ noOrder: true });
                }
            });

        apiService.getFeatures(sessionGuid)
            .then(res => {
                if (res && res.length > 0 && res.filter(f => f.name === administrationConst).length === 1) {
                    this.setState({
                        isAdmin: true
                    })
                } 
            })
            .catch((e) => {
               
            });
    };

    render() {
        const { customer, i18n, history } = this.props;
        const { lastOrder, isAdmin } = this.state;

        const lastOrderBox = lastOrder ? (
            <InfoBox
                className='feedback'
                title={i18n.lastOrder}
                description={lastOrder.orderItems.map(item => {
                    return (
                        <div className='item' key={item.orderItemId}>
                            <div className='name'>{i18n.prop(item, 'name')}</div>
                            <div className='count'>x {item.count}</div>
                        </div>
                    )
                })}
                buttonLabel={i18n.viewAllOrders}
                onButtonClick={() => history.push('/orders')}
            />)
            : (this.state.noOrder ? null : <Spinner />);

        if (!customer) {
            return <Redirect to='/' />
        }
        return (
            <div className='account-page'>
                <div className='header account-bg'>
                    <div className='logo kredens-logo' onClick={() => history.push('/')}></div>
                    <div className='greetings'>{i18n.hiUpper(customer.name)}</div>
                    <div className='exit' onClick={this.signOut}>Вийти</div>
                    {isAdmin ? <div className='admin' onClick={() => history.push('/admin')}>Адмін</div> : null}
                    <div className='phoneNumber'>{formatPhoneNumber(customer.phoneNumber)}</div>
                    <div className='bonuses'>{customer.bonuses > 0 ? i18n.bonusesCoffee(customer.bonuses) : i18n.noBonuses}</div>
                    <div className='bonus-description'>{i18n.wePresentCoffee}</div>
                </div>
                <div className='body'>
                    {lastOrderBox}
                    <InfoBox
                        className='feedback'
                        title={i18n.yourThought}
                        description={i18n.leaveFeedbackPlease}
                        buttonLabel={i18n.leaveFeedback}
                        onButtonClick={() => history.push('/feedbackdirect')}
                    />
                </div>
                <div className='footer'>
                    <VestaButton
                        preImage={'arrow-left'}
                        label={i18n.backToMenu}
                        onButtonClick={() => history.push('/')}
                    />
                </div>
            </div>
        );
    };
}

const mapStateToProps = ({ customer, i18n, sessionGuid }) => {
    return { customer, i18n, sessionGuid }
};

export default connect(mapStateToProps, { userSignedOut })
    (withRouter(withApiService(AccountPage)));