import React from 'react'
import VestaButton from '../../vesta-button'
import VestaInput from '../../vesta-input'
import './feedback-input.css'

const FeedbackInput = ({
    title,
    subtitle,
    buttonLabel,
    buttonImage,
    buttonClick,
    innerComponent,
    className,
    placeHolder,
    inputValue,
    onValueChange,
    buttonDisabled
}) => {

    return (
        <div className={`feedback-input ${className}`}>
            <div className='title'>{title}</div>
            <div className='subtitle'>{subtitle}</div>
            {innerComponent}
            <VestaInput 
                placeHolder={placeHolder}
                value={inputValue}
                onValueChange={onValueChange}
                multiline               
            />
            <VestaButton 
                label={buttonLabel} 
                onButtonClick={buttonClick} 
                image={buttonImage}
                theme={'black'} 
                disabled={buttonDisabled}
                />
        </div>
    )
};

export default FeedbackInput;