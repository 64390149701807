import React from 'react';
import VestaButton from '../../vesta-button';
import './collapsed-cart.css';

const CollapsedCart = ({ total, payClick, i18n, editClick }) => {
    return (
        <div className='collapsed-cart'>
            <VestaButton
                label={i18n.payOrder}
                onButtonClick={payClick}
                image={'arrow-white'}
            />
            <div className='bottom'>
                <div className='total-label'>{i18n.totalLabel}</div>
                <div className='total'>{total}</div>
                <div className='uah'>₴</div>
                <div className='edit-link' onClick={editClick}>{i18n.edit}</div>
            </div>
        </div>
    );
};

export default CollapsedCart;
