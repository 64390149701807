const formatPhoneNumber = (phoneNumber) => {
    return `+${phoneNumber.substring(0, 3)} `
        + `${phoneNumber.substring(3, 5)} `
        + `${phoneNumber.substring(5, 8)} `
        + `${phoneNumber.substring(8, 10)} `
        + `${phoneNumber.substring(10, 12)}`;
};

const getOrderItemFullName = (item) => {
    let name = `${item.nameUa},${item.sizeUa}`;
    if (item.orderItemAdditions && item.orderItemAdditions.length > 0) {
        item.orderItemAdditions.forEach(a => {
            name += `,${a.nameUa}`;
        });
    }
    return name;
}

const calculateTotal = (order) => {
    return order.orderItems.reduce(
        (t, i) =>
            t + (getOrderItemFullPrice(i) * i.count), 0);
}

const getOrderItemFullPrice = (item) => {
    if (item.orderItemAdditions && item.orderItemAdditions.length > 0) {
        return item.price + item.orderItemAdditions.reduce((t, i) => t + i.price, 0);
    }
    return item.price;
}

const getLocationHour = (locationHour) => {
    return Math.floor(locationHour / 100);
};

const getLocationMinute = (locationHour) => {
    return locationHour % 100;
}

const formatHour = (locationHour) => {
    return getLocationHour(locationHour).toString().padStart(2, '0') + ':' + getLocationMinute(locationHour).toString().padStart(2, '0')
}

const isLocationClosed = (location) => {
    if(location.manualOpenState){
        return !location.isOpen;
    }
    const currentDate = new Date();
    const locationOpenDate = new Date();
    locationOpenDate.setHours(getLocationHour(location.openHour), getLocationMinute(location.openHour), 0, 0);
    const locationCloseDate = new Date();
    locationCloseDate.setHours(getLocationHour(location.closeHour), getLocationMinute(location.closeHour), 0, 0);
    return currentDate < locationOpenDate || currentDate >= locationCloseDate;
}

export { formatPhoneNumber, getOrderItemFullName, calculateTotal, getOrderItemFullPrice, getLocationHour, getLocationMinute, formatHour, isLocationClosed};