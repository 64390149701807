import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signInDataChanged } from '../../../actions';
import './register-page.css';
import VestaButton from '../../vesta-button';
import VestaInput from '../../vesta-input';
import { withRouter } from 'react-router-dom'
import withApiService from '../../hoc/with-api-service'
import Spinner from '../../spinner';

class RegisterPage extends Component {
    state = {
        sendDisabled: true,
        loading: false
    };

    onPhoneChange = (value) => {
        const { signInDataChanged, signInData } = this.props;
        this.setState({
            sendDisabled: value.length !== 12 || !signInData.userName,
        });
        signInDataChanged({
            phoneNumber: value,
            userName: signInData.userName
        });
    };

    onNameChange = (value) => {
        const { signInDataChanged, signInData } = this.props;
        this.setState({
            sendDisabled: signInData.phoneNumber.length !== 12 || !value,
            userName: value
        });
        signInDataChanged({
            phoneNumber: signInData.phoneNumber,
            userName: value
        });
    };

    sendButtonClick = () => {
        const { apiService, history, signInData } = this.props;
        this.setState({
            loading: true
        });
        apiService.sendCode(signInData.phoneNumber)
            .then((res) => {
                this.setState({
                    loading: false
                });
                if (res.success) {
                    history.push('/verify');
                }
            });
    };

    signInClick = () => {
        const { history } = this.props;
        history.push('/signin');
    };

    componentDidMount = () => {
        this.onPhoneChange(this.props.signInData.phoneNumber ?? '');
    };

    render() {
        if(this.state.loading){
            return <Spinner />
        }

        const { i18n, signInData } = this.props;

        return (
            <div className='register-page signin-bg'>
                <div className='logo kredens-logo' />
                <div className='input-box'>
                    <div className='header'>
                        <span>{i18n.registration}</span>
                    </div>
                    <div className='description'>
                        <span>{this.state.verificationCodeSent ? i18n.enterCode : i18n.collectBonuses}</span>
                    </div>
                    <div className='name'>
                        <VestaInput
                            type="text"
                            placeHolder={i18n.whatIsYourName}
                            onValueChange={this.onNameChange}
                            value={signInData.userName}
                            focused />
                    </div>
                    <div className='phone'>
                        <VestaInput
                            type="phone"
                            placeHolder={i18n.phoneNumber}
                            onValueChange={this.onPhoneChange}
                            value={signInData.phoneNumber} />
                    </div>
                    <div className='submit'>
                        <VestaButton
                            label={this.state.userNotFound ? i18n.register : i18n.send}
                            image={this.state.sendDisabled ? 'arrow-disabled' : 'arrow-white'}
                            disabled={this.state.sendDisabled}
                            onButtonClick={this.sendButtonClick}
                            theme='black' />
                    </div>
                    <div className='footer'>
                        <span onClick={this.signInClick}>{i18n.registered}</span>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ signInData, i18n }) => {
    return { signInData, i18n }
};

export default connect(mapStateToProps, { signInDataChanged })(withApiService(withRouter(RegisterPage)));