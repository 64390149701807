import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import VestaButton from '../vesta-button';
import './about.css';
import CartHeader from '../cart/cart-views/cart-header';
import PaymentInfo from './payment-info';
import DeliveryInfo from './delivery';
import Agreement from './agreement';
import Contacts from './contacts';

const About = ({ i18n, history }) => {

    const closeClick = () => {
        history.push('/products');
    };
    let infoContent = null;
    let infoTitle = null;
    switch (history.location.pathname) {
        case '/paymentinfo':
            infoContent = <PaymentInfo />;
            infoTitle = 'ОПЛАТА ТА ОТРИМАННЯ ЗАМОВЛЕННЯ'
            break;
        case '/deliveryinfo':
            infoContent = <DeliveryInfo />;
            infoTitle = 'ДОСТАВКА'
            break;
        case '/agreement':
            infoContent = <Agreement />;
            infoTitle = 'ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ'
            break;

        case '/contacts':
            infoContent = <Contacts />;
            infoTitle = 'КОНТАКТНА ІНФОРМАЦІЯ'
            break;
        default:
            break;
    }

    const aboutPopup = (
        <div className='about-popup'>
            <CartHeader title={infoTitle} closeClick={closeClick}></CartHeader>
            <div className='info'>
                {infoContent}
            </div>
            <div className='footer'>
                <VestaButton
                    label={i18n.backToMenu}
                    onButtonClick={closeClick}
                    preImage='arrow-left'
                />
            </div>
        </div>
    )

    return (
        <Fragment>
            <div className='about'>
                <div className='links'>
                    <div className='copy'>© Kredens, 2020</div>
                    <a href='https://www.kredens.com.ua/paywebapp' target='blank' className='link'>Оплата та отримання замовлення</a>
                    <a href='https://www.kredens.com.ua/offer-web-app' target='blank' className='link'>Договір публічної оферти</a>
                    <a href='https://www.kredens.com.ua/contacts' target='blank' className='link'>Контактна інформація</a>
                </div>
            </div>
            {infoContent ? aboutPopup : null}
        </Fragment>
    );
};

export default About;