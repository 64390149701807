import React from 'react'
import { Route } from 'react-router-dom';
import ManageGroups from './groups';
import ManageProducts from './products';
import ManageAdditions from './additions';
import EditProduct from './edit-product';
import './manage-menu.css';

const ManageMenu = (props) => {
    return (
        <div className='manage-menu'>
            <Route path='/admin/managemenu/groups/:id?' render={
                (p) => <ManageGroups {...p} {...props} />} />
            <Route path='/admin/managemenu/additions/:id?' render={
                (p) => <ManageAdditions {...p} {...props} />} />
            <Route path='/admin/managemenu/products' exact>
                <ManageProducts {...props} />
            </Route>
            <Route path='/admin/managemenu/products/:id/:groupid?' exact render={
                (p) => <EditProduct {...p} {...props} />} />
            <Route path='/admin/managemenu' exact >
                <div className="action-button" onClick={() => props.history.push(`/admin/managemenu/groups`)}>Групи</div>
                <div className="action-button" onClick={() => props.history.push(`/admin/managemenu/products`)}>Продукти</div>
                <div className="action-button" onClick={() => props.history.push(`/admin/managemenu/additions`)}>Додатки</div>
            </Route>

        </div>
    );
};

export default ManageMenu;