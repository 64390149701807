import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import FeedbackInput from '../../feedback/feedback-input';
import FeedbackRate from '../../feedback/feedback-rate';
import FeedbackBox from '../../feedback/feedback-box';
import withApiService from '../../hoc/with-api-service'

import './feedback-page.css'
import Spinner from '../../spinner';

const FeedbackPage = ({ sessionGuid, vestaLocation, customer, i18n, apiService, history, direct }) => {

    const [state, setState]
        = useState({
            rate: 0,
            feedback: '',
            feedbackState: 'rating'
        });



    const onRateChanged = (r) => {
        setState({
            ...state,
            rate: r
        });
    };

    const onFeedbackChanged = (value) => {
        setState({
            ...state,
            feedback: value
        });
    };

    const nextScreen = (canSave) => {
        setState({
            ...state,
            feedbackState: state.feedbackState === 'rating' ? (canSave ? 'thanks' : 'why') : 'sorry'
        });
    };

    const sendFeedbackClick = () => {
        const canSave = state.feedback.trim().length > 0 || state.rate > 2;

        if (canSave) {
            setState({
                ...state,
                feedbackState: 'loading'
            });
            apiService
                .postFeedback(
                        state.feedback, 
                        state.rate, 
                        sessionGuid, 
                        vestaLocation ? vestaLocation.locationId : 0 )
                .then(result => {
                    if (result.success) {
                        nextScreen(canSave);
                    }
                });
        } else {
            nextScreen(canSave);
        }
    };

    const shareClick = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Kredens app',
                text: i18n.checkoutApp,
                url: process.env.REACT_APP_BASEURL,
            })
                .then(() => history.push('/'))
                .catch((error) => console.log('Error sharing', error));
        }
    };

    if (!customer) {
        return <Redirect to='/' />
    }

    switch (state.feedbackState) {
        case 'loading':
            return (
                <Spinner onTop />
            );
        case 'thanks':
            return (
                <div className='feedback-page-overflow'>
                    <FeedbackBox
                        image={'smile-winky'}
                        title={i18n.thanksForFeedback}
                        subtitle1={i18n.recommendUs}
                        button1Label={i18n.shareLink}
                        button1Click={shareClick}
                        button2Label={i18n.backToMenu}
                        button2Click={() => history.push('/')}
                    />
                </div>
            );
        case 'why':
            return (
                <div className='feedback-page-overflow'>
                    <FeedbackBox
                        image={'smile-shock'}
                        title={i18n.whatHappened}
                        subtitle1={i18n.tellUsWhatsWrong}
                        button1Label={i18n.leaveComment}
                        button1Click={() => setState({ ...state, feedbackState: 'leave-feedback' })}
                    />
                </div>
            );
        case 'sorry':
            return (
                <div className='feedback-page-overflow'>
                    <FeedbackBox
                        image={'smile-sad'}
                        title={i18n.weAreSorry}
                        subtitle1={i18n.weWillGetItFixed}
                        button1Label={i18n.backToMenu}
                        button1Click={() => history.push('/')}
                    />
                </div>
            );
        case 'leave-feedback':
            return (
                <div className='feedback-page yellow-bg'>
                    <div className='logo'></div>
                    <FeedbackInput
                        buttonLabel={i18n.send}
                        buttonImage={'arrow-white'}
                        buttonDisabled={state.feedback.trim() === ''}
                        inputValue={state.feedback}
                        placeHolder={i18n.addComment}
                        buttonClick={sendFeedbackClick}
                        onValueChange={onFeedbackChanged}
                        className='text'
                        title={i18n.shareYourTought}
                        subtitle={i18n.tellUsWhatsWrong}
                    />
                </div>
            );
        default:
            return (
                <div className='feedback-page yellow-bg'>
                    <div className='logo'></div>
                    <FeedbackInput
                        buttonLabel={i18n.send}
                        buttonImage={'arrow-white'}
                        buttonDisabled={state.rate === 0}
                        inputValue={state.feedback}
                        placeHolder={i18n.addComment}
                        buttonClick={sendFeedbackClick}
                        onValueChange={onFeedbackChanged}
                        className='text'
                        innerComponent={(<FeedbackRate rate={state.rate} rateChanged={onRateChanged} />)}
                        title={direct ? i18n.rateOurApp : i18n.yourOrderIsReady}
                        subtitle={i18n.rateOurAppAndMakeUseBetter}
                    />
                </div>
            );
    }
};
const mapStateToProps = ({ sessionGuid, customer, i18n, vestaLocation }) => {
    return { sessionGuid, customer, i18n, vestaLocation }
};

export default connect(mapStateToProps)(withApiService(withRouter(FeedbackPage)));