import React from 'react';
import './spinner.css';
import Loader from 'react-loader-spinner'

const Spinner = ({ onTop }) => {
  return (
    <div className={`spinner ${onTop ? 'ontop' : ''}`}>
      <Loader className='loader' type="ThreeDots" color="#1A1A1A" height={80} width={80} />
    </div>
  )
};

export default Spinner;