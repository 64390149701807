import React from 'react';
import { connect } from 'react-redux';
import './payment-success-page.css'
import { withRouter, Redirect } from 'react-router-dom'
import withApiService from '../../hoc/with-api-service';
import { calculateTotal } from '../../../common'
import FeedbackBox from '../../feedback/feedback-box';
import VestaButton from '../../vesta-button';
import ReactGA from 'react-ga';

class PaymentSuccessPage extends React.Component {

    state = {
        lastOrder: null,
        bonusAmountApplied: 0
    }

    componentDidMount() {
        const { apiService, sessionGuid } = this.props;
        apiService.getLastOrder(sessionGuid)
            .then(result => {
                if (result.success && result.order) {
                    ReactGA.event({
                        category: 'Order',
                        action: 'paid',
                        label: result.order.orderId.toString(),
                        value: calculateTotal(result.order)
                    });
                    this.setState({
                        lastOrder: result.order,
                        bonusAmountApplied: result.bonusAmountApplied
                    });
                }
            });
    };

    render() {
        const { i18n, customer, history } = this.props
        const { lastOrder, bonusAmountApplied } = this.state;

        if (!customer) {
            return <Redirect to='/' />
        }

        //const lastOrderCount = bonusAmountApplied;//lastOrder ? lastOrder.orderItems.reduce((t, i) => i.count + t, 0) : 0;
        let coffeeDesc;
        let additionDesc;
        let coffee2Desc;
        if (bonusAmountApplied === 1) {
            coffeeDesc = 'каву';
            additionDesc = 'додаткове';
            coffee2Desc = 'зернятко';
        } else if (bonusAmountApplied > 1 && bonusAmountApplied < 5) {
            coffeeDesc = 'кави';
            additionDesc = 'додаткових';
            coffee2Desc = 'зернятка';
        } else if (bonusAmountApplied > 4) {
            coffeeDesc = 'кав';
            additionDesc = 'додаткових';
            coffee2Desc = 'зерняток';
        }
        const address = lastOrder && lastOrder.location ? lastOrder.location.addressUa : '';
        const description = lastOrder && lastOrder.state === 4
        ? (<div className='description'>Ви придбали {bonusAmountApplied} {coffeeDesc}. Ваше замовлення готується на {address}</div>)
        : (<div className='description'>Ви придбали {bonusAmountApplied} {coffeeDesc}, Вам нараховано {additionDesc} {bonusAmountApplied} {coffee2Desc}. Ваше замовлення готується на {address}</div>)
        const noBonusDescription = (<div className='description'>Ваше замовлення готується на {address}</div>)
        return (
            <div className='payment-success account-bg'>
                <div className='kredens-logo' onClick={() => history.push('/')}></div>
                <div className='thanks1'>{i18n.thanks}</div>
                <div className='thanks2'>{i18n.yourOrderAccepted}</div>
                {bonusAmountApplied > 0 ? description : noBonusDescription}
                <FeedbackBox
                    image={'star'}
                    title={i18n.shareYourTought}
                    subtitle1={i18n.rateOurApp}
                    subtitle2={i18n.andHelpBecomeBetter}
                    button1Label={i18n.rateApp}
                    button1Click={() => history.push('/feedback')} />
                <VestaButton
                    className='back-to-menu'
                    label={i18n.backToMenu}
                    theme={'whitesolid'}
                    onButtonClick={() => history.push('/')} />
            </div>
        )
    };

}

const mapStateToProps = ({ customer, i18n, sessionGuid }) => {
    return { customer, i18n, sessionGuid }
};
export default connect(mapStateToProps)(withApiService(withRouter(PaymentSuccessPage)));
