import React from 'react';
import './vesta-button.css';

class VestaButton extends React.Component {
    buttonClick = () => {
        const { disabled, onButtonClick } = this.props;
        if (disabled) {
            return;
        } else {
            onButtonClick();
        }
    }

    render() {
        const { label, disabled, theme = 'white', image, preImage, className } = this.props;

        const buttonImage = image ? (
            <div className={`image ${image ? image : ''}`}></div>
        ) : null;
        const buttonPreImage = preImage ? (
            <div className={`pre-image ${preImage ? preImage : ''}`}></div>
        ) : null;

        return (
            <div
                className={`vesta-button ${theme} ${disabled ? 'disabled' : ''} ${className ? className : ''}`}
                onClick={this.buttonClick}
            >
                <div className='content'>
                    {buttonPreImage}
                    <div className={`label  ${!image && !preImage ? 'noimage' : ''}`}>{label}</div>
                    {buttonImage}
                </div>
            </div>
        );
    }
};

export default VestaButton;
