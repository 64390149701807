import React, { useState, useEffect, useCallback } from 'react';
import Spinner from '../../spinner';
import EditField from './edit-field'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from 'react-select'
// import Modal from '@material-ui/core/Modal';
// import { makeStyles } from '@material-ui/core/styles';
import './products.css';

// function getModalStyle() {
//     const top = 50;
//     const left = 50;

//     return {
//         top: `${top}%`,
//         left: `${left}%`,
//         transform: `translate(-${top}%, -${left}%)`,
//     };
// };

// const useStyles = makeStyles((theme) => ({
//     paper: {
//         position: 'absolute',
//         width: 400,
//         backgroundColor: theme.palette.background.paper,
//         border: '2px solid #000',
//         boxShadow: theme.shadows[5],
//         padding: theme.spacing(2, 4, 3),
//     },
// }));

const EditProduct = ({ apiService, history, match }) => {
    const [loading, setLoading] = useState(true);
    //const [newAdditionOpen, setNewAdditionOpen] = useState(true);
    const [product, setProduct] = useState(null);
    const [groups, setGroups] = useState([]);
    const [additions, setAdditions] = useState([]);
    const [selectedAddition, setSelectedAddition] = useState(null);
    const [overrideAddition, setOverrideAddition] = useState({ item: null, addition: null, price: 0 });
    const [removedProductItems, setRemovedProductItems] = useState([]);
    const [removedProductAdditions, setRemovedProductAdditions] = useState([]);
    const [removedProductAdditionOverrides, setRemovedProductAdditionOverrides] = useState([]);

    // const modalStyle = getModalStyle();
    // const modalClasses = useStyles();

    const back = useCallback(async () => {
        history.push('/admin/managemenu/products')
    }, [history]);


    const loadAdditionalData = useCallback(async () => {
        apiService.getAllGroups()
            .then(res => {
                setGroups(res);
                apiService.getAdditions()
                    .then(res => {
                        setAdditions(res);
                        setLoading(false);
                    })
            });
    }, [apiService]);

    const load = useCallback(async () => {
        setLoading(true);
        if (!match || !match.params.id) {
            history.push('/admin/managemenu/products');
        }
        if (match.params.id === '0') {
            setProduct({
                nameUa: '',
                nameEn: '',
                descriptionUa: '',
                descriptionEn: '',
                specialDescriptionUa: '',
                specialDescriptionEn: '',
                specialBackgroundUrl: '',
                productId: 0,
                productGroupId: match.params.groupid,
                isActive: false,
                productItems: [],
                productAdditions: []
            });
            loadAdditionalData();
        } else {
            apiService.getProduct(match.params.id)
                .then((res) => {
                    if (!res || res.success) {
                        back();
                    }
                    setProduct(res);
                    loadAdditionalData();
                });
        }
    }, [apiService, match, history, back, loadAdditionalData]);

    useEffect(() => {
        load();
    }, [load]);

    const saveProduct = () => {
        setLoading(true);
        apiService.removeProductItems(removedProductItems)
            .then(() => {
                setRemovedProductItems([]);
                apiService.removeProductAdditions(removedProductAdditions)
                    .then(() => {
                        setRemovedProductAdditions([]);
                        apiService.removeProductAdditionOverrides(removedProductAdditionOverrides)
                            .then(() => {
                                setRemovedProductAdditionOverrides([]);
                                apiService.saveProduct(product)
                                    .then((res) => {
                                        if (res) {
                                            history.push(`/admin/managemenu/products/${res.productId}`);
                                        }

                                    });
                            });
                    });
            });

    };

    const updateProductItem = (oldPi, newPi) => {
        const index = product.productItems.indexOf(oldPi);
        setProduct({
            ...product,
            productItems: [
                ...product.productItems.slice(0, index),
                newPi,
                ...product.productItems.slice(index + 1),
            ]
        });
    };

    const removeProductItem = (pi) => {
        const index = product.productItems.indexOf(pi);

        setProduct({
            ...product,
            productItems: [
                ...product.productItems.slice(0, index),
                ...product.productItems.slice(index + 1),
            ]
        });

        if (pi.productItemId) {
            setRemovedProductItems([
                ...removedProductItems,
                pi.productItemId
            ])
        }
    };

    const removeAddition = (a) => {
        const index = product.productAdditions.indexOf(a);

        setProduct({
            ...product,
            productAdditions: [
                ...product.productAdditions.slice(0, index),
                ...product.productAdditions.slice(index + 1),
            ]
        });

        if (a.productAdditionProductId) {
            setRemovedProductAdditions([
                ...removedProductAdditions,
                a.productAdditionProductId
            ])
        }
    };

    const removeAdditionOverride = (pi, o) => {
        const indexItem = product.productItems.indexOf(pi);
        const indexAdditionOverride = product.productItems[indexItem].additionOverrides.indexOf(o);

        const items = [
            ...product.productItems.slice(0, indexItem),
            {
                ...pi,
                additionOverrides: [
                    ...product.productItems[indexItem].additionOverrides.slice(0, indexAdditionOverride),
                    ...product.productItems[indexItem].additionOverrides.slice(indexAdditionOverride + 1),
                ]
            },
            ...product.productItems.slice(indexItem + 1),
        ]
        setProduct({
            ...product,
            productItems: items
        });

        if (o.productItemAdditionOverrideId) {
            setRemovedProductAdditionOverrides([
                ...removedProductAdditionOverrides,
                o.productItemAdditionOverrideId
            ])
        }
    };


    const addNewProductItem = () => {
        setProduct({
            ...product,
            productItems: [
                ...product.productItems,
                {
                    productItemId: 0,
                    productId: product.productId,
                    sizeUa: '',
                    sizeEn: '',
                    price: 0,
                    oldPrice: undefined,
                    bonusAmount: 1,
                    additionOverrides: []
                }
            ]
        });
    };

    if (loading) {
        return <Spinner />
    }

    const productItemEdit = (property, productItem) => (
        <TableCell style={{ padding: '2px' }}>
            <EditField
                target={productItem}
                property={property}
                set={(update) => updateProductItem(productItem, update)}
            />
        </TableCell>
    );

    const tableCell = (value) => (
        <TableCell style={{ padding: '2px' }}>{value}</TableCell>
    );

    const additionsOptions = additions
        .filter(a => !product.productAdditions.find(a1 => a1.productAddition.productAdditionId === a.productAdditionId))
        .map(a => { return { value: a, label: `${a.nameUa}, ${a.price}, ${a.groupNum}, ${a.selected ? 'x' : ''}` } });

    const additionsOverrideOptions = product.productAdditions
        .map(a => { return { value: a.productAddition, label: `${a.productAddition.nameUa}` } });

    const itemsOverrideOptions = product.productItems
        .map(pi => { return { value: pi, label: `${pi.sizeUa}` } });

    const addAddition = () => {
        if (!selectedAddition) {
            return;
        }

        product.productAdditions.push({
            productAdditionProductId: 0,
            productAdditionId: selectedAddition.productAdditionId,
            productId: product.productId,
            productAddition: { ...selectedAddition }
        });

        setSelectedAddition(null);
    };

    const addAdditionOverride = () => {
        if (!overrideAddition || !overrideAddition.item || !overrideAddition.addition) {
            return;
        }

        product.productItems
            .find(pi => pi.productItemId === overrideAddition.item.productItemId)
            .additionOverrides.push({
                productItemAdditionOverrideId: 0,
                productItemId: overrideAddition.item.productItemId,
                productAdditionId: overrideAddition.addition.productAdditionId,
                price: overrideAddition.price
            });

        setOverrideAddition({item: null, addition: null, price: 0});
    };

    // const selectedAdditionView = selectedAddition.map(a => (
    //     <div className='addition-overrides'>
    //         <div className></div>
    //     </div>
    // ));

    return (
        <div className='edit-product'>
            <div className='header'>
                <div className='header-label'>{product.nameUa}</div>
                <div className='back action-button' onClick={back}>Назад до списку продуктів</div>
            </div>
            <div className='row'>
                <EditField
                    label='Назва'
                    target={product}
                    property='nameUa'
                    set={setProduct} />
                <EditField
                    label='Назва (англ)'
                    target={product}
                    property='nameEn'
                    set={setProduct} />
            </div>
            <div className='row'>
                <EditField
                    type='select'
                    label='Група'
                    data={groups}
                    dataValueProp='productGroupId'
                    dataLabelProp='nameUa'
                    target={product}
                    property='productGroupId'
                    set={setProduct} />

                <EditField
                    type='checkbox'
                    label='Активний'
                    target={product}
                    property='isActive'
                    set={setProduct} />
            </div>

            <div className='row'>
                <EditField
                    type='textarea'
                    label='Опис'
                    target={product}
                    property='descriptionUa'
                    set={setProduct} />
                <EditField
                    type='textarea'
                    label='Опис (англ)'
                    target={product}
                    property='descriptionEn'
                    set={setProduct} />
            </div>
            <div className='row'>
                <EditField
                    type='textarea'
                    label='Спеціальний опис'
                    target={product}
                    property='specialDescriptionUa'
                    set={setProduct} />
                <EditField
                    type='textarea'
                    label='Спеціальний опис (англ)'
                    target={product}
                    property='specialDescriptionEn'
                    set={setProduct} />
            </div>

            <EditField
                label='Фон акції'
                target={product}
                property='specialBackgroundUrl'
                set={setProduct} />
            <hr />
            <div className='header-label'>Позиції</div>
            <TableContainer >
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            {tableCell('Назва')}
                            {tableCell('Назва (англ)')}
                            {tableCell('Ціна')}
                            {tableCell('Ціна стара')}
                            {tableCell('Бонуси')}
                            {tableCell('')}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {product.productItems.map((pi) => (
                            <TableRow key={`${pi.productItemId}_${product.productItems.indexOf(pi)}`}>
                                {productItemEdit('sizeUa', pi)}
                                {productItemEdit('sizeEn', pi)}
                                {productItemEdit('price', pi)}
                                {productItemEdit('oldPrice', pi)}
                                {productItemEdit('bonusAmount', pi)}
                                <TableCell><div className='action-button' onClick={() => removeProductItem(pi)}>Вилучити</div></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className='action-button' onClick={addNewProductItem}>Додати</div>
            <hr />
            <div className='header-label'>Додатки</div>
            <TableContainer >
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            {tableCell('Назва')}
                            {tableCell('Ціна')}
                            {tableCell('Група')}
                            {tableCell('вибрано')}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {product.productAdditions.map((a) => (
                            <TableRow key={`${a.productAdditionProductId}_${product.productAdditions.indexOf(a)}`}>
                                {tableCell(a.productAddition.nameUa)}
                                {tableCell(a.productAddition.price)}
                                {tableCell(a.productAddition.groupNum)}
                                {tableCell(a.productAddition.selected ? 'x' : '')}
                                <TableCell className='addition-actions'>
                                    <div className='action-button' onClick={() => removeAddition(a)} >Вилучити</div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className='additions-add'>
                <Select
                    className='additions-select'
                    placeholder='Виберіть додаток'
                    options={additionsOptions}
                    value={selectedAddition
                        ? {
                            value: selectedAddition,
                            label: `${selectedAddition.nameUa}, ${selectedAddition.price}, ${selectedAddition.groupNum}, ${selectedAddition.selected ? 'x' : ''}`
                        }
                        : null}
                    onChange={(e) => setSelectedAddition(e.value)}
                />
                <div className='action-button' onClick={addAddition}>Додати</div>
            </div>
            <hr />
            <div className='header-label'>Ціни додатків по позиціях</div>
            <TableContainer >
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            {tableCell('Позиція')}
                            {tableCell('Додаток')}
                            {tableCell('Ціна')}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {product.productItems.map((pi) => {
                            return pi.additionOverrides.map(o => (
                                <TableRow key={`${pi.productItemId}_${o.productAdditionId}_${pi.additionOverrides.indexOf(o)}`}>
                                    {tableCell(pi.sizeUa)}
                                    {tableCell(additions.find(a => a.productAdditionId === o.productAdditionId).nameUa)}
                                    {tableCell(o.price)}
                                    <TableCell className='addition-actions'>
                                        <div className='action-button' onClick={() => removeAdditionOverride(pi, o)} >Вилучити</div>
                                    </TableCell>

                                </TableRow>
                            ));
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className='additions-add'>
                <Select
                    className='product-item-select'
                    placeholder='Виберіть позицію'
                    options={itemsOverrideOptions}
                    value={overrideAddition.item
                        ? {
                            value: overrideAddition.item,
                            label: `${overrideAddition.item.sizeUa}`
                        }
                        : null}
                    onChange={(e) => setOverrideAddition({ ...overrideAddition, item: e.value })}
                />
                <Select
                    className='additions-select'
                    placeholder='Виберіть додаток'
                    options={additionsOverrideOptions}
                    value={overrideAddition.addition
                        ? {
                            value: overrideAddition.addition,
                            label: `${overrideAddition.addition.nameUa}`
                        }
                        : null}
                    onChange={(e) => setOverrideAddition({ ...overrideAddition, addition: e.value })}
                />
                <EditField
                    className='addition-override-price'
                    label='Ціна'
                    target={overrideAddition}
                    property='price'
                    set={setOverrideAddition} />
                <div className='action-button add-addition-override' onClick={addAdditionOverride}>Додати</div>
            </div>
            <hr />
            <div className='actions'>
                <div className='action-button' onClick={saveProduct}>Зберегти</div>
                <div className='action-button' onClick={back}>Відмінити</div>
            </div>

        </div>
    );
}

export default EditProduct;