import React from 'react';

const getBonusEndingUa = (bonuses) => {
    if (bonuses === 0) return 'бонусів';
    if (bonuses === 1) return 'бонус';
    if (bonuses >= 2 && bonuses <= 4) return 'бонуси';
    if (bonuses >= 5) return 'бонусів';
};

const getBonusEndingEn = (bonuses) => {
    if (bonuses === 1) return 'bonus';
    return 'bonuses'
};

const getCoffees = (bonuses) => {
    return Math.floor(bonuses / 10);
};

const getBeans = (bonuses) => {
    return bonuses - (Math.floor(bonuses / 10) * 10)
};

const i18n = {
    langHome: {
        ua: "DO YOU SPEAK ENGLISH?",
        en: "РОЗМОВЛЯЄТЕ УКРАЇНСЬКОЮ?"
    },
    coninueAsGuest: {
        ua: "ПРОДОВЖИТИ ЯК ГІСТЬ",
        en: "CONTINUE AS GUEST"
    },
    registered: {
        ua: "МАЄШ РЕЄСТРАЦІЮ? ТОБІ СЮДИ",
        en: "REGISTERED? CLICK HERE"
    },
    register: {
        ua: "ЗАРЕЄСТРУВАТИСЬ",
        en: "REGISTER"
    },
    registration: {
        ua: "РЕЄСТРАЦІЯ",
        en: "REGISTRATION"
    },
    collectBonuses: {
        ua: <span>Накопичуй <b>вдвічі більше бонусів</b>!</span>,
        en: <span>Collect <b>twice more bonuses</b>!</span>,
    },
    bonusDescription: {
        ua: "Щоб накопичувати подвійну кількість бонусів треба бути зареєстрованим, проте це можна зробити пізніше ;)",
        en: "To collect double bonus amount you have to be registered, however, you can do it later ;)"
    },
    enter: {
        ua: "УВІЙТИ",
        en: "SIGN IN"
    },
    phoneNumber: {
        ua: "Номер телефону",
        en: "Phone number"
    },
    send: {
        ua: "НАДІСЛАТИ",
        en: "SEND"
    },
    notExistedPhone: {
        ua: "Такий номер телефону вiдcутнiй.\nБудь ласка вкажiть iнший номер або зареєструйтесь.",
        en: "Phone number is not registered.\nPlease, enter another one or register."
    },
    whatIsYourName: {
        ua: "Як Вас звати?",
        en: "What is your name?"
    },
    invalidCode: {
        ua: "Код невірний. Будь ласка повторіть.",
        en: "Invalid code. Please, enter again."
    },
    sendSmsToAnotherNumber: {
        ua: "Відправити СМС на інший номер?",
        en: "Send SMS to another number?"
    },
    enterCode: {
        ua: "Введіть код із СМС:",
        en: "Enter code from SMS"
    },
    guest: {
        ua: "Гість",
        en: "Guest"
    },
    hi: {
        ua: (name) => <span>Вітаємо, <b>{name}</b></span>,
        en: (name) => <span>Greetings, <b>{name}</b></span>,
    },
    hiUpper: {
        ua: (name) => <span>ВІТАЄМО, <b>{name}</b>!</span>,
        en: (name) => <span>GREETINGS, <b>{name}</b>!</span>,
    },
    bonuses: {
        ua: (bonus) => <span>У вас <span className='bonus-amount'><b>{bonus}</b></span> {getBonusEndingUa(bonus)}</span>,
        en: (bonus) => <span>You have <span className='bonus-amount'><b>{bonus}</b></span> {getBonusEndingEn(bonus)}</span>,
    },
    noBonuses: {
        ua: "У Вас, поки що, немає бонусів",
        en: "You have no bonuses yet"
    },
    bonusesExtended: {
        ua: (bonus) => <span>У вас <b>{bonus} {getBonusEndingUa(bonus)}</b>, збирай ще {10 - bonus} і отримуй каву!</span>,
        en: (bonus) => <span>You have <b>{bonus} {getBonusEndingEn(bonus)}</b>, collect {10 - bonus} more and get a coffee!</span>,
    },
    bonusesExtendedMore: {
        ua: (bonus) => <span>У вас <b>{bonus} {getBonusEndingUa(bonus)}</b>, сплатіть ними і отримайте каву!</span>,
        en: (bonus) => <span>You have <b>{bonus} {getBonusEndingEn(bonus)}</b>, pay with them and get a coffee!</span>,
    },
    bonusesCoffee: {
        ua: (bonuses) => getBeans(bonuses) > 0
            ? (<span>У вас <b>{getCoffees(bonuses)}</b> <div className='coffee-cup' /> та <b>{getBeans(bonuses)}</b> <div className='coffee-bean' /></span>)
            : (<span>У вас <b>{getCoffees(bonuses)}</b> <div className='coffee-cup' /></span>),
        en: (bonuses) => getBeans(bonuses) > 0
            ? <span>You have <b>{getCoffees(bonuses)}</b> <div className='coffee-cup' /> and <b>{getBeans(bonuses)}</b> <div className='coffee-bean' /></span>
            : <span>You have <b>{getCoffees(bonuses)}</b> <div className='coffee-cup' /></span>
    },
    wePresentCoffee: {
        ua: "Відтепер картка лояльності завжди буде з тобою! Збирайте 10 кавових зерняток та отримуйте 1 каву безкоштовно",
        en: "We give 1 free coffee for every 25uah spent",
    },

    specials: {
        ua: "СПЕЦІАЛЬНІ ПРОПОЗИЦІЇ",
        en: "Specials"
    },
    payOrder: {
        ua: "СПЛАТИТИ ЗАМОВЛЕННЯ",
        en: "PAY FOR ORDER"
    },
    addToOrder: {
        ua: "ДОДАТИ ДО ЗАМОВЛЕННЯ",
        en: "ADD TO ORDER"
    },
    totalLabel: {
        ua: "Сума замовлення:",
        en: "Order total:"
    },
    edit: {
        ua: "Редагувати",
        en: "Edit"
    },
    editOrder: {
        ua: "РЕДАГУВАННЯ ЗАМОВЛЕННЯ",
        en: "ORDER EDIT"
    },
    finishEdit: {
        ua: "ЗАВЕРШИТИ РЕДАГУВАННЯ",
        en: "FINISH EDIT"
    },
    add: {
        ua: "Додати",
        en: "Add"
    },
    emptyOrder1: {
        ua: "Тут зараз порожньо і сумно...",
        en: "It's sad and empty here..."
    },
    emptyOrder2: {
        ua: "Додайте щось, що Вас зігріє.",
        en: "Add something that will warm You."
    },
    yourOrder: {
        ua: "ВАШЕ ЗАМОВЛЕННЯ",
        en: "YOUR ORDER"
    },
    payWith: {
        ua: "СПЛАТИТИ З",
        en: "PAY WITH"
    },
    payAtCounter: {
        ua: "СПЛАТИТИ НА КАСI",
        en: "PAY AT COUNTER"
    },
    payOnline: {
        ua: "СПЛАТИТИ ОНЛАЙН",
        en: "PAY ONLINE"
    },
    payWithCash: {
        ua: "СПЛАТИТИ ГОТІВКОЮ",
        en: "PAY WITH CASH"
    },
    payWithBonuses: {
        ua: "СПЛАТИТИ БОНУСАМИ",
        en: "PAY WITH BONUSES"
    },
    additions: {
        ua: "Додатки:",
        en: "Additions:"
    },
    backToMenu: {
        ua: "ПОВЕРНУТИСЬ ДО МЕНЮ",
        en: "BACK TO MENU"
    },
    yourThought: {
        ua: "НАМ ЦІКАВА ТВОЯ ДУМКА",
        en: "WE APPRECIATE YOUR OPINION"
    },
    leaveFeedbackPlease: {
        ua: "Залиште будь ласка свій відгук.",
        en: "Please, provide your feedback"
    },
    leaveFeedback: {
        ua: "ЗАЛИШИТИ ВІДГУК",
        en: "PROVIDE FEEDBACK"
    },
    letBeFriends: {
        ua: "ДАВАЙ БУДЕМО ДРУЗЯМИ",
        en: "LET'S BE FRIENDS"
    },
    fastAccessToCoffee: {
        ua: "Швидкий доступ до смачної кави.",
        en: "Quick access to delicious coffee"
    },
    addToPhone: {
        ua: "ДОДАТИ ДО СМАРТФОНУ",
        en: "ADD TO SMARTPHONE"
    },
    lastOrder: {
        ua: "ОСТАННЄ ЗАМОВЛЕННЯ",
        en: "LAST ORDER"
    },
    lastOrders: {
        ua: "ОСТАННІ ЗАМОВЛЕННЯ",
        en: "LAST ORDERS"
    },
    viewAllOrders: {
        ua: "ПЕРЕГЛЯНУТИ ВСІ ЗАМОВЛЕННЯ",
        en: "VIEW ALL ORDERS"
    },
    back: {
        ua: "ПОВЕРНУТИСЬ",
        en: "BACK"
    },
    orderAgain: {
        ua: "ЗАМОВИТИ ЗНОВ",
        en: "ORDER AGAIN"
    },
    thanks: {
        ua: "ДЯКУЄМО!",
        en: "THANK YOU!"
    },
    yourOrderAccepted: {
        ua: "ВАШЕ ЗАМОВЛЕННЯ ПРИЙНЯТО",
        en: "YOUR ORDER HAS BEEN ACCEPTED"
    },
    whatIsNextDescription: {
        ua: (phone) => <span>Наш бариста до вас зателефонує за номером <b>{phone}</b>, щоб узгодити час і деталі доставки</span>,
        en: (phone) => <span>Our barista will call you on <b>{phone}</b> to negotiate delivery time and details</span>
    },
    yourPaymentDeclined: {
        ua: "НАЖАЛЬ, ПЛАТІЖ НЕ ПРИЙНЯТИЙ",
        en: "SORRY, PAYMENT WAS DECLINED"
    },
    tryPaymentAgain: {
        ua: "Будь ласка, повторіть платіж.",
        en: "Please, try again."
    },
    retryPayment: {
        ua: "ПОВТОРИТИ ПЛАТІЖ",
        en: "RETRY PAYMENT"
    },
    backToOrder: {
        ua: "ПОВЕРНУТИСЬ ДО ЗАМОВЛЕННЯ",
        en: "BACK TO ORDER"
    },
    shareYourTought: {
        ua: "Поділися своєю думкою",
        en: "Share your thoughts"
    },
    rateOurApp: {
        ua: "Оціни наш додаток",
        en: "Rate our app"
    },
    andHelpBecomeBetter: {
        ua: "і допоможи нам стати ще кращими",
        en: "and help us become even better"
    },
    rateApp: {
        ua: "Оцінити додаток",
        en: "Rate app"
    },
    thanksForFeedback: {
        ua: "ДЯКУЄМО ЗА ВАШУ ОЦІНКУ!",
        en: "THANK YOU FOR YOUR FEEDBACK!"
    },
    recommendUs: {
        ua: "Порадьте нас друзям ;)",
        en: "Recommend us to your friends ;)"
    },
    shareLink: {
        ua: "ПОДІЛИТИСЬ ПОСИЛАННЯМ",
        en: "SHARE LINK"
    },
    whatHappened: {
        ua: "Що сталося?",
        en: "What happened?"
    },
    tellUsWhatsWrong: {
        ua: "Будь ласка розкажіть нам що не так.",
        en: "Please, tell us what is wrong."
    },
    weAreSorry: {
        ua: "Нам дуже прикро",
        en: "We are very sorry"
    },
    weWillGetItFixed: {
        ua: "Ми будемо намагатись це виправити.",
        en: "We will try to get it fixed."
    },
    addComment: {
        ua: "Додати коментар",
        en: "Add comment"
    },
    yourOrderIsReady: {
        ua: 'Ваше замовлення готове!',
        en: "Your order is ready"
    },
    rateOurAppAndMakeUseBetter: {
        ua: "Оціни наш додадок та допоможи нам стати ще кращими для тебе.",
        en: "Rate our app and help use to become better for you."
    },
    leaveComment: {
        ua: "Залишити коментар",
        en: "Leave comment"
    },
    weHaveGoods: {
        ua: "В нас багато що є, оберіть на свій смак :)",
        en: "We have a lot of things, choose what you like :)"
    },
    checkoutApp: {
        ua: "Ти тільки поглянь який чудовий сайт замовлень з Kredens cafe",
        en: "Check out great site for ordering from Kredens cafe"
    },
    youOrdered: {
        ua: "Ви замовили:",
        en: "You ordered:"
    }

};

export default i18n;