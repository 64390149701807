import React from 'react'
import './manage-bonus.css';
import Spinner from '../../spinner';


class ManageBonus extends React.Component {

    state = {
        customer: null,
        searchString: '',
        bonus: 0,
        isLoading: false
    };

    setBonus = () => {
        const { customer, bonus } = this.state;
        const { apiService } = this.props;

        if (customer && bonus >= 0) {
            this.setState({ isLoading: true });
            apiService.setBonus(customer.customerId, bonus)
                .then(res => {
                    this.findCustomer();
                });
        }
    };

    findCustomer = () => {
        const { apiService } = this.props;
        const { searchString } = this.state;
        if (!searchString) {
            return;
        }
        this.setState({ isLoading: true });
        apiService.findCustomer(searchString)
            .then(res => {
                if (res) {
                    this.setState({ customer: res, bonus: res.bonuses });
                } else {
                    this.setState({ customer: undefined })
                }
                this.setState({ isLoading: false });
            });
    };

    render() {
        const { customer, isLoading, bonus, searchString } = this.state;
        if (isLoading) {
            return <Spinner onTop />
        }
        const customerView = customer ? (
            <div className='customer'>
                <div className='name'>Ім'я: {customer.name}</div>
                <div className='phone'>Номер тел.: {customer.phoneNumber}</div>
                <div className='bonus'>
                    <div className='bonus-label'>Бонуси: </div>
                    <div className='input'><input type='number' value={bonus} onChange={(e) => { this.setState({ bonus: e.target.value }) }} /></div>
                    <div className='button' onClick={this.setBonus}>Встановити</div>
                </div>
            </div>
        ) : customer === undefined ? <div><hr/>Не знайдено</div> : null;

        return (
            <div className='manage-bonus'>
                <div className='search'>
                    <div className='search-input'><input type='text' value={searchString} onChange={(e) => { this.setState({ searchString: e.target.value }) }} /></div>
                    <div className='search-button' onClick={this.findCustomer}>Шукати</div>
                </div>
                {customerView}
            </div>
        );
    }
};

export default ManageBonus;