import React from 'react';
import VestaApiServiceContext from '../vesta-api-service-context';

const withApiService = (Wrapped) => {

  return (props) => {
    return (
      <VestaApiServiceContext.Consumer>
        {
          (apiService) => {
            return (
              <Wrapped {...props} apiService={apiService} />
            );
          }
        }
      </VestaApiServiceContext.Consumer>
    );
  }
};

export default withApiService;