import React from 'react'
import './manage-locations.css';
import Spinner from '../../spinner';


class EditLocation extends React.Component {
    state = {
        isLoading: false,
        location: null
    };

    loadData = () => {
        const { apiService, id } = this.props;
        if (id.toString() === '0') {
            this.setState({
                location: {
                    addressUa: '',
                    addressEn: '',
                    code: '',
                    openHour: 900,
                    closeHour: 2100,
                    telegramChatId: 0,
                    isActive: false,
                    manualOpenState: false,
                    isOpen: false
                }, isLoading: false
            });
            return;
        }
        this.setState({ isLoading: true });
        apiService.getLocations()
            .then(res => {
                const apiLocation = res.find(r => r.locationId.toString() === id.toString());
                this.setState({
                    location: {
                        ...apiLocation,
                        isOpen: apiLocation.isOpen ?? false
                    }, isLoading: false
                });
            });
    }

    changeProperty = (prop, val) => {
        const oldLocation = this.state.location;
        oldLocation[prop] = val;
        this.setState({
            location: { ...oldLocation }
        })
    };

    componentDidMount = () => {
        this.loadData();
    }

    renderRow = (label, input) => {
        return (
            <div className='form-row'>
                <div className='label'>{label}:</div>
                <div className='value'>
                    {input}
                </div>
            </div>
        );
    }

    renderTextRow = (prop, label, isInt) => {
        const { location } = this.state;
        return this.renderRow(label, (
            <input type="text" value={location[prop]}
                onChange={(e) =>
                    this.changeProperty(prop, isInt ? parseInt(e.target.value) : e.target.value)} />
        ));
    }

    renderCheckBox = (prop, label) => {
        const { location } = this.state;
        return this.renderRow(label, (
            <input type="checkbox" checked={location[prop]}
                onChange={(e) =>
                    this.changeProperty(prop, e.target.checked)} />
        ));
    }

    saveLocation = () => {
        const { location } = this.state;
        const { apiService, history } = this.props;

        this.setState({ isLoading: true });
        apiService.saveLocation(location)
            .then(res => {
                if(res && res.success){
                    history.push(`/admin/managelocations/${res.location.locationId}`);
                    this.loadData();
                }else {
                    console.log(res ? res.error : 'error saving location');
                }
            });
    };

    render = () => {
        const { onBack } = this.props;
        const { isLoading, location } = this.state;
        if (isLoading || !location) {
            return <Spinner onTop />
        }

        return (
            <div className='edit-location'>
                <div className='header'>
                    <div className='back action-button' onClick={onBack}>Назад до списку локацій</div>
                    <div className='name'><b>{location.addressUa}</b></div>
                </div>
                <hr />
                <div className='form'>
                    {this.renderTextRow('addressUa', 'Адреса (ua)')}
                    {this.renderTextRow('addressEn', 'Адреса (en)')}
                    {this.renderTextRow('code', 'Код')}
                    {this.renderTextRow('openHour', 'Відкрито з')}
                    {this.renderTextRow('closeHour', 'Відкрито по')}
                    {this.renderTextRow('telegramChatId', 'Код телеграм чату', true)}
                    {this.renderCheckBox('isActive', 'Активна')}
                    {this.renderCheckBox('manualOpenState', 'Ручне керування')}
                    {location.manualOpenState ? this.renderCheckBox('isOpen', 'Відкрито') : null}
                </div>
                <hr />
                <div className='save action-button' onClick={this.saveLocation}>Зберегти</div>
            </div>);
    }
};

export default EditLocation;