import React, { Component } from 'react';
import withApiService from '../hoc/with-api-service';
import { connect } from 'react-redux';
import { specialsLoaded } from '../../actions';
import Product from '../product';
import './specials.css';

class Specials extends Component {

    componentDidMount() {
        const { apiService, specialsLoaded } = this.props;
        apiService.getSpecials()
            .then(res => {
                specialsLoaded(res);
            });
    }

    render() {
        const { i18n, specials, itemClick, orderItemClick, order } = this.props;
        const specialsFiltered = specials ? specials.filter(p => p.isActive) : null;
        
        if (!specialsFiltered || specialsFiltered.length === 0) {
            return null;
        }

        const specialsViewItems = specialsFiltered.map((product) => {
            return (
                <Product
                    key={product.productId}
                    product={product}
                    order={order}
                    i18n={i18n}
                    itemClick={itemClick} 
                    orderItemClick={orderItemClick} />
                //const productItem = product.productItems[0];
                // const orderedItem = order.orderItems.reduce((t, i) => t.concat(i.productItems), [])
                //     .find(i => i.productItemId === productItem.productItemId);
                // return (
                //     <div className={`item ${product.specialBackgroundUrl}`} key={product.productId} onClick={() => itemClick(product, productItem)}>
                //         <div className='item-header'>{i18n.prop(product, 'name')}</div>
                //         <div className='item-description'>{i18n.prop(product, 'specialDescription')}</div>
                //         <div className='prices'>
                //             <div className='old-price'>{productItem.oldPrice}</div>
                //             <div className='price'>{productItem.price} ₴</div>
                //         </div>
                //     </div>
            );
        });

        return (
            <div className='specials' >
                <div className='specials-header'>{i18n.specials.toUpperCase()}</div>
                <div className='items-outer'>
                    <div className='items-container'>
                        {specialsViewItems}
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ specials, i18n, order }) => {
    return { specials, i18n, order }
};

export default connect(mapStateToProps, { specialsLoaded })(withApiService(Specials));