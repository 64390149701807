import React, { Component } from 'react';
import './sign-in-page.css';
import VestaButton from '../../vesta-button';
import VestaInput from '../../vesta-input';
import withApiService from '../../hoc/with-api-service'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { signInDataChanged } from '../../../actions';
import Spinner from '../../spinner';

class SignInPage extends Component {

    state = {
        submitDisabled: true,
        userNotFound: false,
        loading: false
    };

    onPhoneChange = (value) => {
        const { signInDataChanged, signInData } = this.props;
        this.setState({
            submitDisabled: value.length !== 12,
            phoneNumber: value,
            userNotFound: false
        });

        signInDataChanged({
            phoneNumber: value,
            userName: signInData.userName
        });
    };

    continueButtonClick = () => {
        const { apiService, history } = this.props;

        if (this.state.userNotFound) {
            history.push('/register');
            return;
        }
        this.setState({
            loading: true
        });

        // apiService.sendCode(signInData.phoneNumber)
        // .then((res) => {
        //     this.setState({
        //         loading: false
        //     });
        //     if (res.success) {
        //         history.push('/verify');
        //     }
        // });

        apiService.phoneexists(this.state.phoneNumber)
            .then((res) => {
                if (res.success) {
                    apiService.sendCode(this.state.phoneNumber)
                        .then((res) => {
                            this.setState({
                                loading: false
                            });
                            if (res.success) {
                                history.push('/verifysignin');
                            }
                        });
                } else {
                    this.setState({
                        userNotFound: true,
                        loading: false
                    });
                }
            });
    };

    coninueAsGuestClick = () => {
        const { apiService, onSignIn } = this.props;
        apiService.signInGuest()
            .then((res) => {
                if (res.success) {
                    onSignIn(res);
                }
            });
    };

    componentDidMount = () => {
        this.onPhoneChange(this.props.signInData.phoneNumber ?? '');
    };

    render() {
        if (this.state.loading) {
            return <Spinner />
        }
        const { i18n, signInData } = this.props;
        const errorDescription = this.state.userNotFound ?
            <div className='error'>
                <span>{i18n.notExistedPhone}</span>
            </div>
            : null;
        return (
            <div className='sign-in-page signin-bg'>
                <div className='logo kredens-logo' />
                <div className='input-box'>
                    <div className='header'>
                        <span>{i18n.enter}</span>
                    </div>
                    <div className='phone'>
                        <VestaInput
                            type='phone'
                            onValueChange={this.onPhoneChange}
                            placeHolder={i18n.phoneNumber}
                            inputClassName={this.state.userNotFound ? 'error' : null}
                            value={signInData.phoneNumber}
                            focused />
                    </div>
                    {errorDescription}
                    <div className='submit'>
                        <VestaButton
                            label={i18n.send}
                            image={this.state.submitDisabled ? 'arrow-disabled' : 'arrow-white'}
                            disabled={this.state.submitDisabled}
                            onButtonClick={this.continueButtonClick}
                            theme='black'
                        />
                        <div className='register'>
                            <Link to='/register' >{i18n.register}</Link>
                        </div>
                    </div>
                    <div className='footer'>
                        {/* <span onClick={this.coninueAsGuestClick}>{i18n.coninueAsGuest}</span> */}
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = ({ signInData, i18n }) => {
    return { signInData, i18n }
};

export default connect(mapStateToProps, { signInDataChanged })(withApiService(withRouter(SignInPage)));