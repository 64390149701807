import React from 'react';
import { connect } from 'react-redux';
import OrderState from '../../consts/order-state';
import CollapsedCart from './cart-views/collapsed-cart';
import AddToCart from './cart-views/add-to-cart';
import EditCart from './cart-views/edit-cart';
import PaymentSelect from './cart-views/payment-select';
import withApiService from '../hoc/with-api-service';
import { withRouter } from 'react-router-dom'
import { calculateTotal, getOrderItemFullName, formatHour, isLocationClosed } from '../../common'
import ReactGA from 'react-ga';

import {
    addSelectedProductToOrderRequested,
    addToOrderCanceled,
    orderPaymentRequested,
    orderPaid,
    incOrderItem,
    decOrderItem,
    removeOrderItem,
    changeAddition,
    editOrderRequested,
    editOrderDismissed,
    editOrderConfirmed,
    editOrderItemConfirmed,
    editOrderItemDismissed,
    editOrderItemRemoveConfirmed,
    customerDataChanged,
    userSignedOut,
    orderHasChanged,
    orderUpdated
} from '../../actions';
import EditItem from './cart-views/edit-item';

class CartContanier extends React.Component {
    saveOrder = () => {
        const { setLoading, apiService, order, sessionGuid, orderUpdated, userSignedOut } = this.props;
        setLoading(true);
        apiService.saveOrder(order, sessionGuid)
            .then(result => {
                setLoading(false);
                if (result.success) {
                    orderUpdated(result.order);
                } else if (result.errorCode === 2) {
                    userSignedOut();
                }

            });
    };

    componentDidUpdate(prevProps) {
        const { hasOrderChanged, orderHasChanged, orderState } = this.props;
        orderHasChanged(false);
        if (hasOrderChanged) {
            this.saveOrder();
        }
        if (orderState !== prevProps.orderState && orderState !== OrderState.empty) {
            const modalName = Object.keys(OrderState).find(k => OrderState[k] === orderState);
            ReactGA.modalview(modalName);
        }
    }
    render() {
        const {
            i18n,
            lang,
            customer,
            selectedProduct,
            apiService,
            orderState,
            addToOrderCanceled,
            incOrderItem,
            decOrderItem,
            removeOrderItem,
            changeAddition,
            order,
            orderEdit,
            sessionGuid,
            editOrderRequested,
            editOrderDismissed,
            editOrderConfirmed,
            orderPaymentRequested,
            customerDataChanged,
            editOrderItemConfirmed,
            editOrderItemDismissed,
            editOrderItemRemoveConfirmed,
            orderPaid,
            history,
            addSelectedProductToOrderRequested,
            setLoading,
            orderHasChanged,
            selectLocation,
            vestaLocation,
            vestaLocations,
            setLocation,
            userSignedOut,
            productGroups            
        } = this.props;


        const isClosed = (l) => {
            const location = l ?? this.props.order.location;
            if (isLocationClosed(location)) {
                if (location.manualOpenState) {
                    alert(`Вибачте, але заклад за адресою ${location.addressUa} зачинено.`);
                } else {
                    alert(`Вибачте, але заклад за адресою ${location.addressUa} зачинено.\nГодини роботи ${formatHour(location.openHour)}-${formatHour(location.closeHour)}`);
                }
                return true;
            }
            return false;
        }

        const payClick = () => {
            ReactGA.event({
                category: 'Order',
                action: 'Pay button clicked'
            });
            setLoading(true);
            apiService.saveOrder(order, sessionGuid)
                .then(result => {
                    setLoading(false);
                    if (result.success) {                       
                        if (isClosed(result.order.location)) {
                            return;
                        }            
                        orderPaymentRequested();
                    } else if (result.errorCode === 2) {
                        userSignedOut();
                    }
                })
            ;
        };

        const payWithClick = (state) => {
            ReactGA.event({
                category: 'Order',
                action: 'Pay with clicked',
                label: state.toString()
            });

            if (isClosed()) {
                return;
            }
            setLoading(true);
            apiService.saveOrder(order, sessionGuid)
                .then(result => {
                    if (result.success) {
                        apiService.setOrderState(result.order.orderId, state, sessionGuid)
                            .then(res => {
                                setLoading(false);
                                if (res.success) {
                                    customerDataChanged({
                                        bonuses: res.order.customer.bonuses
                                    });
                                    orderPaid();
                                    history.push('/paymentstatussuccess');
                                }
                            })
                    } else if (result.errorCode === 2) {
                        userSignedOut();
                    }
                })
        };

        const editClick = () => {
            ReactGA.event({
                category: 'Order',
                action: 'Order edit requested'
            });
            editOrderRequested();
        };

        const editCloseClick = () => {
            ReactGA.event({
                category: 'Order',
                action: 'Order edit dismissed'
            });
            editOrderDismissed();
        };

        const editConfirmedClick = () => {
            ReactGA.event({
                category: 'Order',
                action: 'Order edit confirmed'
            });
            editOrderConfirmed();
            orderHasChanged(true);
        };

        const getNameFromSelectedProduct = () => {
            if (!selectedProduct || !selectedProduct.orderItems || !selectedProduct.orderItems.length) {
                return undefined;
            }
            return getOrderItemFullName(selectedProduct.orderItems[0])
        };

        const addToOrderClick = () => {
            ReactGA.event({
                category: 'Order',
                action: 'Add item confirmed',
                label: getNameFromSelectedProduct()
            });
            addSelectedProductToOrderRequested();
            orderHasChanged(true);
        };

        const editItemConfirmedClick = () => {
            ReactGA.event({
                category: 'Order',
                action: 'Edit item confirmed',
                label: getNameFromSelectedProduct()
            });
            editOrderItemConfirmed();
            orderHasChanged(true);
        };

        const editItemDismissedClick = () => {
            ReactGA.event({
                category: 'Order',
                action: 'Edit item dismissed',
                label: getNameFromSelectedProduct()
            });
            editOrderItemDismissed();
        };

        const editItemRemoveConfirmedClick = () => {
            ReactGA.event({
                category: 'Order',
                action: 'Edit item removed',
                label: getNameFromSelectedProduct()
            });
            editOrderItemRemoveConfirmed();
        };

        const incClick = (item) => {
            const newCount = item.count + 1;
            ReactGA.event({
                category: 'Order',
                action: 'Item count increased',
                label: getOrderItemFullName(item),
                value: item.count
            });
            if (newCount > 999) {
                return;
            }
            item.count = newCount;
            incOrderItem({ ...item });
        };

        const decClick = (item) => {
            const newCount = item.count - 1;
            ReactGA.event({
                category: 'Order',
                action: 'Item count decreased',
                label: getOrderItemFullName(item),
                value: newCount
            });

            if (newCount < 0) {
                return;
            }
            item.count = newCount;
            decOrderItem({ ...item });
        };

        const removeItemClick = (item) => {
            ReactGA.event({
                category: 'Order',
                action: 'Item removed',
                label: getOrderItemFullName(item)
            });
            removeOrderItem(item);
        };

        const additionClick = (addition, item) => {
            ReactGA.event({
                category: 'Order',
                action: 'Item addition changed',
                label: getOrderItemFullName(item) + ': ' + addition.nameUa
            });
            changeAddition(addition, item);
        };

        const addToOrderCloseClick = () => {
            ReactGA.event({
                category: 'Order',
                action: 'Add item dismissed'
            });
            addToOrderCanceled();
        };

        if (history.location.pathname !== '/products') {
            return null;
        }

        switch (orderState) {
            case OrderState.empty:
                return null;
            case OrderState.collapsed:
                return <CollapsedCart
                    total={calculateTotal(order)}
                    i18n={i18n}
                    payClick={payClick}
                    editClick={editClick} />
            case OrderState.add:
                return <AddToCart
                    selectedProduct={selectedProduct}
                    i18n={i18n}
                    addToOrderClick={addToOrderClick}
                    incClick={incClick}
                    decClick={decClick}
                    additionClick={additionClick}
                    closeClick={addToOrderCloseClick}
                />
            case OrderState.edit:
                return <EditCart
                    order={orderEdit}
                    i18n={i18n}
                    additionClick={additionClick}
                    incClick={incClick}
                    decClick={decClick}
                    removeItemClick={removeItemClick}
                    closeClick={editCloseClick}
                    confirmedClick={editConfirmedClick}
                />
            case OrderState.edititem:
                return <EditItem
                    order={order}
                    selectedProduct={selectedProduct}
                    i18n={i18n}
                    addToOrderClick={editItemConfirmedClick}
                    incClick={incClick}
                    decClick={decClick}
                    additionClick={additionClick}
                    closeClick={editItemDismissedClick}
                    removeClick={editItemRemoveConfirmedClick}
                />
            case OrderState.pay:
                return <PaymentSelect
                    isClosed={isClosed}
                    order={order}
                    i18n={i18n}
                    customer={customer}
                    lang={lang}
                    sessionGuid={sessionGuid}
                    payClick={payWithClick}
                    closeClick={editCloseClick}
                    setLoading={setLoading}
                    editClick={editClick}
                    selectLocation={selectLocation}
                    vestaLocation={vestaLocation}
                    vestaLocations={vestaLocations}
                    setLocation={setLocation}
                    userSignedOut={userSignedOut}
                    productGroups={productGroups}/>
            default:
                return null;
        }
    }
};

const mapStateToProps = ({
    customer,
    productGroups,
    i18n,
    lang,
    orderState,
    selectedProduct,
    selectedOrderItem,
    hasOrderChanged,
    order,
    orderEdit,
    sessionGuid,
    vestaLocations }) => {
    return {
        customer,
        productGroups,
        i18n,
        lang,
        orderState,
        selectedProduct,
        selectedOrderItem,
        hasOrderChanged,
        order,
        orderEdit,
        sessionGuid,
        vestaLocations
    }
};

export default connect(mapStateToProps,
    {
        addSelectedProductToOrderRequested,
        addToOrderCanceled,
        orderPaymentRequested,
        orderPaid,
        incOrderItem,
        decOrderItem,
        removeOrderItem,
        editOrderRequested,
        editOrderDismissed,
        editOrderConfirmed,
        editOrderItemConfirmed,
        editOrderItemDismissed,
        editOrderItemRemoveConfirmed,
        changeAddition,
        customerDataChanged,
        userSignedOut,
        orderHasChanged,
        orderUpdated
    })(withRouter(withApiService(CartContanier)));

