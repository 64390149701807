import React, { useEffect, useState } from 'react';
import Spinner from '../../spinner';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import './products.css';

const ManageProducts = (props) => {
    const { apiService, history } = props;
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [filter, setFilter] = useState('');
    const [active, setActive] = useState(true);

    useEffect(() => {
        setLoading(true);
        apiService.getAllGroupsWithProducts()
            .then((res) => {
                setGroups(res);
                setLoading(false);
            });
    }, [apiService]);

    if (loading) {
        return <Spinner />
    }

    const productsView = (product) => (
        <div className='product' key={product.productId}>
            <div className={`name${product.isActive ? '' : ' disabled'}`} >{product.nameUa}</div>
            <div className='actions'>
                <div className='edit action-button' onClick={() => history.push(`/admin/managemenu/products/${product.productId}`)}>Редагувати</div>
            </div>
        </div>
    );

    const filterProduct = (p) => {
        return (!filter || (filter && p.nameUa.toLowerCase().indexOf(filter.toLowerCase())>=0 )) 
        && (active ? p.isActive : true)
    }

    const groupsView = groups.filter(g => g.products.filter(filterProduct).length).map(g => (
        <div className='group' key={g.productGroupId}>
            <div className='header'>
                <div className='group-label'>{g.nameUa}</div>
                <div className='action-button' onClick={() => history.push(`/admin/managemenu/products/0/${g.productGroupId}`)}>Додати</div>
            </div>

            {g.products.filter(filterProduct).map(productsView)}

            <hr />
        </div>
    ));

    return (
        <div className='manage-products'>
            <div className='header'>
                <div className='list-header'>Продукти</div>
                <div className='back action-button' onClick={() => history.push('/admin/managemenu')}>Назад</div>
                <TextField
                    margin='dense'
                    size='small'
                    label='Пошук'
                    value={filter}
                    variant='outlined'
                    onChange={(e) => setFilter(e.target.value)} />
                <FormControlLabel control={
                    <Checkbox
                        size='small'
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)} />}
                    label='активні'
                />
            </div>
            {groupsView}
        </div>
    );
};

export default ManageProducts;