const actionTypes = {
    languageChanged: 'LANGUAGE_CHANGED',
    userSignedIn: 'USER_SIGNED_IN',
    userSignedOut: 'USER_SIGNED_OUT',
    signInDataChanged: 'SIGN_IN_DATA_CHANGED',
    productGroupsLoaded: 'PRODUCT_GROUPS_LOADED',
    specialsLoaded: 'SPECIALS_LOADED',
    requestAddToOrder: 'REQUEST_ADD_TO_ORDER',
    incOrderItem: 'INC_ORDER_ITEM',
    decOrderItem: 'DEC_ORDER_ITEM',
    changeAddition: 'CHANGE_ADDITION',
    removeOrderItem: 'REMOVE_ORDER_ITEM',
    orderPaymentRequested: 'ORDER_PAYMENT_REQUESTED',
    orderPaid: 'ORDER_PAID',
    addToOrderRequested: 'ADD_TO_ORDER_REQUESTED',
    addToOrderCanceled: 'ADD_TO_ORDER_CANCELED',
    editOrderRequested: 'EDIT_ORDER_REQUESTED',
    editOrderDismissed: 'EDIT_ORDER_DISMISSED',
    editOrderConfirmed: 'EDIT_ORDER_CONFIRMED',
    addSelectedProductToOrderRequested: 'ADD_SELECTED_PRODUCT_TO_ORDER_REQUESTED',
    customerDataChanged: 'CUSTOMER_DATA_CHANGED',
    orderUpdated: 'ORDER_UPDATED',
    orderHasChanged: 'ORDER_HAS_CHANGED',
    editOrderItemRequested: 'EDIT_ORDERITEM_REQUESTED',
    editOrderItemDismissed: 'EDIT_ORDERITEM_DISMISSED',
    editOrderItemConfirmed: 'EDIT_ORDERITEM_CONFIRMED',
    editOrderItemRemoveConfirmed: 'EDIT_ORDERITEM_REMOVE_CONFIRMED',
    locationsLoaded: 'LOCATIONS_LOADED',
    locationSelected: 'LOCATION_SELECTED'
};

export default actionTypes;