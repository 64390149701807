import React from 'react'
import './feedback-box.css'
import VestaButton from '../../vesta-button'

const FeedbackBox = ({
    image,
    title,
    subtitle1,
    subtitle2,
    button1Label,
    button2Label,
    button1Click,
    button2Click }) => {

    return (
        <div className='feedback-box yellow-bg'>
            <div className={`image ${image}`} />
            <div className='title'>{title}</div>
            <div className='subtitle1'>{subtitle1}</div>
            {subtitle2 ? <div className='subtitle2'>{subtitle2}</div> : null}
            <VestaButton className='button1' label={button1Label} onButtonClick={button1Click} theme={'black'} />
            {button2Label ? <VestaButton className='button2' label={button2Label} onButtonClick={button2Click} theme={'whitesolid'}/> : null}
        </div>
    )
};

export default FeedbackBox;