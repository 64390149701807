
const changeItemCount = (orderedProduct, item) => {
    const neededItem = findItem(orderedProduct.orderItems, item);
    neededItem.count = item.count;
    return {
        ...orderedProduct
    };
}

const additionsAreEqual = (additions1, additions2) => {
    if (!additions1 || !additions2) {
        return false;
    }
    if (additions1.length !== additions2.length) {
        return false;
    }
    let result = true;
    additions1.forEach(a1 => {
        if (!additions2.find(a2 => a2.productAdditionId === a1.productAdditionId)) {
            result = false;
        }
    });

    return result;
};

const getUniqueItemKey = (item) => {
    return item.orderItemAdditions.reduce((key, a) => key += '_' + a.productAdditionId.toString(), item.productItemId.toString())
};

const itemsAreEqual = (item1, item2) => {
    return item1.productItemId === item2.productItemId
        && additionsAreEqual(item1.orderItemAdditions, item2.orderItemAdditions);
}

const findItem = (items, item) => {
    const existed = items
        .find(i =>
            i.productItemId === item.productItemId
            && additionsAreEqual(i.orderItemAdditions, item.orderItemAdditions));
    return existed;
}

const overrideAdditionProps = (item, addition, product) => {
    const additionOverrides = item.additionOverrides ?? product
        .productItems
        .find(i => i.productItemId === item.productItemId).additionOverrides;

    const override = additionOverrides.find(a => a.productAdditionId === addition.productAdditionId);
    if (override) {
        return {
            ...addition,
            price: override.price
        }
    }
    return addition;
}

const createSelectedProduct = (selectedProduct, selectedItem) => {
    const availableAdditions = [...selectedProduct.additions.map(a => overrideAdditionProps(selectedItem, a, selectedProduct))];
    const orderItemAdditions = [...(selectedItem.orderItemAdditions ?? [])];
    if(orderItemAdditions.length === 0){
        availableAdditions.forEach(a => {
            if(a.selected){
                orderItemAdditions.push(a);
            }
        });
    }
    return {
        availableAdditions: availableAdditions,
        orderItems: [{
            ...selectedItem,
            nameUa: selectedProduct.nameUa,
            nameEn: selectedProduct.nameEn,
            descriptionUa: selectedItem.descriptionUa ?? selectedProduct.descriptionUa,
            descriptionEn: selectedItem.descriptionEn ?? selectedProduct.descriptionEn,
            count: selectedItem.count,
            price: selectedItem.price,
            orderItemAdditions: orderItemAdditions
        }],
        groupName: selectedProduct.groupName
    };
}

const toggleAddition = (orderedProduct, item, addition) => {
    return {
        ...orderedProduct,
        orderItems: orderedProduct.orderItems.map(i => {
            if (itemsAreEqual(i, item)) {
                const existed = i.orderItemAdditions.find(a => a.productAdditionId === addition.productAdditionId);
                return {
                    ...i,
                    orderItemAdditions: existed
                        ? i.orderItemAdditions.filter(a => a.productAdditionId !== existed.productAdditionId)
                        : [...i.orderItemAdditions.filter(a => addition.groupNum > 0
                            ? a.groupNum !== addition.groupNum : true), addition]
                }
            }
            return {
                ...i
            };
        })
    }
};

const squashItemsForOrderedProduct = (orderedProduct, reduceCount) => {
    const newOrderedProduct = {
        ...orderedProduct,
        orderItems: orderedProduct.orderItems.reduce((result, item) => {
            const existed = findItem(result, item);
            if (existed) {
                existed.count += item.count
            }
            else if (!reduceCount || item.count > 0) {
                result.push(item);
            }
            return result;
        }, [])
    };

    return newOrderedProduct;
}

const mergeSelectedProductWithOder = (oldOrder, selectedProduct) => {
    const selectedProductFiltered = {
        ...selectedProduct,
        orderItems: [...selectedProduct.orderItems
            .filter(i => i.count > 0).map(i => ({ ...i }))]
    };

    let order = { ...oldOrder };

    selectedProductFiltered.orderItems.forEach(item => {
        const existed = findItem(order.orderItems, item);

        if (existed) {
            order.orderItems = [...order.orderItems
                .filter(i => !itemsAreEqual(i, existed)),
            {
                ...existed,
                count: item.count + existed.count
            }];
        } else {
            order.orderItems.push({
                ...item
            })
        }
    });

    return order;
}

const cleanOrderIdentities = (order) => {
   return cloneOrder(order, true);
}

const cloneOrder = (order, cleanIdentites = false) => {
    return {
        ...order,
        orderId: cleanIdentites ? 0 : order.orderId,
        state: cleanIdentites ? 0 : order.state,
        orderItems: [...order.orderItems.map(item => ({
            ...item,
            orderId : cleanIdentites ? 0 : item.orderId,
            orderItemId: cleanIdentites ? 0 : item.orderItemId,
            orderItemAdditions: [...item.orderItemAdditions.map(addition => ({
                ...addition,
                orderItemAdditionId: cleanIdentites ? 0 : addition.orderItemAdditionId
            }))]
        }))]
    };
}

export {
    changeItemCount,
    squashItemsForOrderedProduct,
    toggleAddition,
    additionsAreEqual,
    findItem,
    itemsAreEqual,
    getUniqueItemKey,
    mergeSelectedProductWithOder,
    cleanOrderIdentities,
    cloneOrder,
    overrideAdditionProps,
    createSelectedProduct
};