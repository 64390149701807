import React from 'react';
import { TextField, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './manage-menu.css';
const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 195,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const EditField = ({ target, type, label, property, set, data, dataValueProp, dataLabelProp }) => {
    const classes = useStyles();

    switch (type) {
        case 'checkbox':
            return (
                <div className='edit-field'>
                    <FormControlLabel control={
                        <Checkbox
                            size='small'
                            label={label}
                            checked={target[property] ?? undefined}
                            onChange={(e) => set({ ...target, [property]: e.target.checked })} />}
                        label={label}
                    />
                </div>
            );
        case 'select':
            return (
                <div className='edit-field'>
                    <FormControl margin='dense' variant='outlined' size='small' className={classes.formControl}>
                        <InputLabel variant='outlined' style={{backgroundColor: 'whitesmoke', padding: '0px 4px'}}>{label}</InputLabel>
                        <Select
                            value={target[property] ?? undefined}
                            onChange={(e) => set({ ...target, [property]: e.target.value })}
                        >
                            {data.map(d => <MenuItem key={d[dataValueProp]} value={d[dataValueProp]}>{d[dataLabelProp]}</MenuItem>)}
                       </Select>
                    </FormControl>

                </div>);
        case 'textarea':
            return (
                <div className='edit-field'>
                    <TextField
                        margin='dense'
                        size='small'
                        multiline
                        rows={4}
                        label={label}
                        value={target[property] ?? undefined}
                        variant='outlined'
                        onChange={(e) => {
                            const newObject = { ...target };
                            newObject[property] = e.target.value;
                            set(newObject)
                        }} />

                </div>);
        case 'textbox':
        default:
            return (
                <div className='edit-field'>
                    <TextField
                        margin='dense'
                        size='small'
                        label={label}
                        value={target[property] ?? undefined}
                        variant='outlined'
                        onChange={(e) => {
                            const newObject = { ...target };
                            newObject[property] = e.target.value;
                            set(newObject)
                        }} />

                </div>
            );
    }
}

export default EditField;