import React from 'react';

const Agreement = () => {
    return (
        <div className='agreement'>
            <div className='title'>Загальні положення</div>
            <div className='par'>
                Цей договір є Договором публічної оферти (відповідно до ст. Ст. 633, 641 і гл. 63 Цивільного кодексу України), його умови  є однакові для всіх юридичних або фізичних осіб. У разі прийняття умов цього Договору  публічної оферти Мобільного додатку, користувач стає Покупцем. При повній згоді з даним Договором Покупець приймає умови та порядок оформлення замовлення, оплати товару, доставки товару, відповідальність за невиконання
                умов цього договору. Договір вважається укладеним з моменту натискання кнопки «Оплатити замовлення» на сторінці оформлення замовлення і підтвердження його покупки.
            </div>
            <div className='title'>Поняття і визначення</div>
            <div className='par'>
                1.1. Мобільний додаток - « KREDENS CAFE», заснований відповідно до Законодавства України, що оперує сайтом, розміщеним в мережі Інтернет за адресою https://order.kredens.com.ua.<br />
                1.2. Товар - опублікований в мобільному додатку перелік товарів.<br />
                1.3. Покупець - фізична особа,  яка отримує інформацію від мобільного додатку,  розміщує замовлення та має намір придбати той чи інший товар і оплатити отримання товару.<br />
                1.4. Продавець – виконавець замовлення Покупця.<br />
                1.5. Замовлення - оформлена і розміщена заявка Покупця в мобільному додатку, для подальшого їх викупу Покупцем.<br />
                1.6. Мобільний додаток - сукупність сторінок,  розташованих в мережі Інтернет за адресою https:// order.kredens.com.ua, що  містить всі ознаки публічної оферти з пошуку, замовлення та оплати Товарів, обраних Покупцем в додатку.<br />
            </div>
            <div className='title'>Предмет Договору</div>
            <div className='par'>
                2.1. Продавець зобов'язується передати у власність Покупця Товар, а Покупець зобов'язується оплатити і прийняти Товар на умовах даного Договору.<br />
                2.2. Цей Договір регулює купівлю-продаж товарів в Мобільному додатку, в тому числі:<br />
                - пошук, замовлення та оплату  Товарів, оформленого Покупцем на Сайті і підтвердженого Мобільним додатком;<br />
                - виготовлення, продаж і доставку Товарів за адресною  доставкою та за допомогою кур'єрських служб, зазначених в мобільному додатку на підставі заявок Покупця, залишених в додатку і підтверджених відповідно зазначених на ньому цін на Товари .<br />
                2.3. Покупець погоджується з умовами цього Договору в момент оформлення Замовлення шляхом натискання на кнопку «Оплатити замовлення», що відображає підтвердження Покупцем прочитання тексту цього Договору і попередню згоду з його умовами, згідно чинного законодавства України,  та приймає до виконання умови договору публічної оферти, які вказані нижче.<br />
                2.4. Продавець надає Покупцеві доступ до всієї необхідної інформації щодо Товару представленого на сайті.<br />
            </div>
            <div className='title'>Порядок оформлення замовлення</div>
            <div className='par'>
                3.1. Покупець має право оформити замовлення на будь-який наявний товар, який знаходиться в мобільному  додатку, шляхом додавання обраних Товарів в віртуальний кошик  і натискаючи кнопку «Оплатити  замовлення».<br />
                3.2. Кожна позиція може бути надана ​​в замовленні в будь-якій кількості.<br />
                3.3. При відсутності товару на складі, Продавця, зобов'язаний поставити Покупця до відома телефону або через електронну пошту.<br />
                3.4. При відсутності товару Покупець має право замінити його аналогічним товаром, відмовитися від даного товару, анулювати замовлення.<br />
                </div>
            <div className='title'>Порядок оплати замовлення</div>
            <div className='par'>
                4.1. Покупець вибирає Товари, розміщені в Мобільному додатку, формує замовлення  а також оплачує на умовах вказаних в мобільному додатку:<br />
                - на умовах передоплати на рахунок Продавця в мобільному додатку, вказаний в замовленні;<br />
                - в момент отримання Товару у відділенні кур'єрської служби;<br />
                - в момент отримання товару в точці продажу Продавця, за вказаною адресою.<br />
                4.2. Загальна вартість Замовлення, що включає повну вартість Товарів і послуг Мобільного додатку, дорівнює сумі що підлягає оплаті і вказується на Сайті при оформленні та в момент розміщення Замовлення.<br />
                4.3.  Якщо кошти від Покупця не надійшли, Продавець залишає за собою право анулювати замовлення.<br />
            </div>
            <div className='title'>Умови доставки замовлення</div>
            <div className='par'>
                5.1. Передача Товарів Покупцеві згідно умов визначених на сайті та обраних Покупцем здійснюється за адресою вказаною в мобільному додатку в момент підтвердження замовлення Продавцем.<br />
                5.2. Доставка товарів (кави та випічки), придбаних в Мобільному додатку, здійснюється кур’єром  в м. Львові  до 24 години з моменту замовлення або до складів транспортних компаній вказаних на сайті Продавця, де здійснюється видача замовленого товару.<br />
                5.2. Разом із замовленням Покупцеві надаються документи згідно законодавства України.<br />
            </div>
            <div className='title'>Умови повернення</div>
            <div className='par'>
                6.1. Повернення товарів або коштів  Покупцю, здійснюється в порядку, передбаченому чинним законодавством України , не пізніше 14 днів з моменту отримання Покупцем товару, лише при умові недотримання Продавцем умов продажу товару.<br />
                6.2. Повернення товару в Продавця проводиться за рахунок Покупця.<br />
                6.3. Повернення коштів Покупцю здійснюється на картку клієнта або готівкою відповідно до чинного законодавства України при умові надання Покупцем достовірних даних згідно п.7.3.<br />
                6.3. При поверненні Покупцем товару неналежної якості, що виникла з вини Продавця, Продавець повертає йому сплачену за товар грошову суму за фактом повернення товару.<br />
            </div>
            <div className='title'>Права і обов'язки сторін</div>
            <div className='par'>
                7.1. Продавець зобов'язаний:<br />
                - забезпечити склад та якість товару, вказаному в додатку;<br />
                - забезпечити обробку замовлення та доставки товару, згідно умов вказаних в Додатку.<br />
                7.2. Покупець має право:<br />
                - в односторонньому порядку припинити надання послуг за цим договором у разі порушення Покупцем умов цього Договору;<br />
                - змінювати ціни на Продукцію в односторонньому порядку, завчасно розміщуючи їх на сайті.<br />
                7.3. Покупець зобов'язаний:<br />
                - своєчасно оплатити та отримати замовлення на умовах цього Договору;<br />
                - під час оформлення замовлення вказати достовірні дані, на яке може бути відправлене та отримане відправлення, в іншому випадку він несе ризик не отримання замовлення або несвоєчасного отримання замовлення.<br />
                7.4. Покупець має право:<br />
                - Отримати оплачений Товар у кількості, що відповідає його замовленню, у неушкодженій упаковці Товару з етикеткою;<br />
                - вимагати від Продавця виконання умов цього Договору.<br />
                </div>
            <div className='title'>Конфіденційність і захист персональних даних</div>
            <div className='par'>
            8.1. При реєстрації в Мобільному додатку Покупець залишає персональні та контактні дані.<br />
            8.2. Надаючи свої персональні дані в Мобільному додатку Покупець залишає персональні та контактні дані.<br />
            8.2. Надаючи свої персональні дані в Мобільному додатку при реєстрації або оформленні Замовлення, Покупець дає свою добровільну згоду на обробку та використання(в тому числі і передачу) своїх персональних даних без обмеження терміну дії такої згоди відповідно до Закону України «Про захист персональних даних» від 01.06.2010 р.<br />
            8.3. Продаець зобов'язується не розголошувати отриману від Покупця інформацію. Не вважається порушенням надання Продавцем інформації третім особам, що діють на підставі договору з Продавцем, в тому числі і для виконання зобов'язань перед Покупцем, а також у випадках, коли розкриття такої інформації встановлено вимогами законодавства.<br />
            8.4. Покупець  н есе від п овідльність за введення сво їх актуальних персональних даних. Продавець не несе відповідальності за неякісне виконання покладених на нього зобов'язань у зв'язку з неактуальністю інформації про Покупця або невідповідністю її дійсності.<br />
        </div>
                <div className='title'>Відповідальність сторін</div>
        <div className='par'>
        9.1. Сторони несуть відповідальність за невиконання або неналежне виконання умов цього договору в порядку, передбаченому цим Договором та чинним законодавством України <br />
        9.2. Покупець, використовуючи наданий йому доступ до мережі Інтернет, самостійно несе відповідальність за шкоду, заподіяну його діями (особисто, або якщо під його логіном знаходилась інша особа) особам або їх майну, юридичним особам, державі.<br />
        9.3. У разі настання обставин непереборної сили, сторони звільняються від виконання умов цього Договору. Під обставинами непереборної сили для цілей цього Договору розміються події, що мають надзвичайний, непередбачений характер, які виключають або об'єктивно заважають виконанню цього Договору, настання яких Сторони не могли передбачити і запобігти розумними способами.<br />
        </div>
        <div className='title'>Інші умови Договору</div>
        <div className='par'>
        10.1. Цей Договір укладено на території України і підпорядковується матеріальному праву України.<br />
        10.2. Недійсність будь-якого пункту або частини цього Договору не призведе до недійсності договору в цілому.<br />
        10.3. Продавець  має право вносити зміни в текст цього Договору на власний розсуд в будь-який момент і без попереднього повідомлення Покупців. Актуальна (діюча) редакція Договору завжди доступна на Сайті https:// order.kredens.com.ua.<br />
        10.4. Сторони мають право розірвати цей Договір в односторонньому порядку, в разі невиконання однією із сторін умов цього Договору та у випадках передбачених чинним законодавством України.<br />
        10.5. Оплата Покупцем оформленого в Мобільному додатку замовлення означає повну згоду Покупця з умовами Договору купівлі-продажу (публічної оферти)<br />
        10.6. Фактичною датою електронної угоди між сторонами є дата прийняття умов Договору згідно п.2.3. , відповідно до ст. 11 Закону України «Про електронну комерцію».<br />
        10.7. Викорстання ресурсу Мобільного додатку для попереднього перегляду овару, а також для оформлення замовлення для Покупця є безкоштовним.<br />
        10.8. Всі суперечки, що виникають між Покупцем та Продавцем, вирішуються шляхом переговорів.У разі недосягнення врегулювання спірного питання, Покупець або Продавець можуть звернутися за вирішенням спору до судових органів відповідно до чинного законодавства України.<br />
            </div>
    </div>
                 )
}

export default Agreement;
