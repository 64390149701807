import React, { useEffect, useState, useCallback } from 'react';
import Spinner from '../../spinner';
import EditField from './edit-field'
import './groups.css';

const ManageGroups = (props) => {
    const { apiService, history, match } = props;
    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelected] = useState(null);

    const load = useCallback(async () => {
        setLoading(true);
        apiService.getAllGroups()
            .then((res) => {
                setGroups(res);
                setLoading(false);
                setSelected(match && match.params.id !== '0'
                    ? res.find(g => g.productGroupId.toString() === match.params.id)
                    : (match && match.params.id === '0' ? { isActive: true } : null));
            });
    }, [apiService, match]);

    useEffect(() => {
        load();
    }, [load]);

    if (loading) {
        return <Spinner />
    }

    const saveSelectedGroup = () => {
        if (!selectedGroup) {
            return;
        }
        setLoading(true);
        apiService.saveGroup(selectedGroup)
            .then(res => {
                if (res) {
                    history.push(`/admin/managemenu/groups/${res.productGroupId}`);
                }
            })
    };

    const back = () => {
        const backUrl = selectedGroup ? '/admin/managemenu/groups' : '/admin/managemenu';
        history.push(backUrl);
    };
    const selectedGroupView = selectedGroup ? (
        <div className='edit-group'>
            <EditField
                label='Назва'
                target={selectedGroup}
                property='nameUa'
                set={setSelected} />
            <EditField
                label='Назва (англ)'
                target={selectedGroup}
                property='nameEn'
                set={setSelected} />
            <EditField
                label='НЕ бонусна (не можна оплатити бонусами)'
                type='checkbox'
                target={selectedGroup}
                property='noBonus'
                set={setSelected} />
            <EditField
                label='Спеціальна (акційна)'
                type='checkbox'
                target={selectedGroup}
                property='isSpecial'
                set={setSelected} />
            <EditField
                label='Активна'
                type='checkbox'
                target={selectedGroup}
                property='isActive'
                set={setSelected} />
            <div className='actions'>
                <div className='action-button' onClick={saveSelectedGroup}>Зберегти</div>
                <div className='action-button' onClick={back}>Відмінити</div>
            </div>
        </div>
    ) : null;


    const groupsView = (
        <div>
            {groups.map(g => (
                <div className='group' key={g.productGroupId}>
                    <div className={`name${g.isActive ? '' : ' disabled'}`} >{g.nameUa}</div>
                    <div className='actions'>
                        <div className='edit action-button' onClick={() => history.push(`/admin/managemenu/groups/${g.productGroupId}`)}>Редагувати</div>
                    </div>
                </div>
            ))}
            <hr />
            <div className='action-button' onClick={() => history.push('/admin/managemenu/groups/0')}>Нова група</div>
        </div>);

    return (
        <div className='groups'>
            <div className='header'>
                <div className='list-header'>Групи{selectedGroup ? ` [${selectedGroup.nameUa}]` : null}</div>
                <div className='back action-button' onClick={back}>Назад</div>
            </div>
            {selectedGroupView ?? groupsView}
        </div>
    );
};

export default ManageGroups;