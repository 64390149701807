import React, { useState } from 'react';
import VestaButton from '../../vesta-button';
import { calculateTotal, getOrderItemFullPrice } from '../../../common'
import './add-to-cart.css'

const AddToCart = ({
    selectedProduct,
    addToOrderClick,
    incClick,
    decClick,
    additionClick,
    i18n,
    closeClick
}) => {

    const itemWithCount = selectedProduct.orderItems.find(i => i.count > 0);
    const [selectedItemId] = useState(itemWithCount
        ? itemWithCount.productItemId
        : selectedProduct.orderItems[0].productItemId);
    const selectedItem = selectedProduct.orderItems.find(i => i.productItemId === selectedItemId);
    let currentGroupNum = 0;
    const groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
    const additionGroupNumCount = groupBy(selectedProduct.availableAdditions, 'groupNum');
    const additions = selectedProduct.availableAdditions.map(addition => {
        const checked = selectedItem.orderItemAdditions.find(a => a.productAdditionId === addition.productAdditionId)
            ? 'checked'
            : '';
        const separator = currentGroupNum !== addition.groupNum && additionGroupNumCount[addition.groupNum].length > 1 ? 'separator' : '';
        currentGroupNum = addition.groupNum;
        return (
            <div className={`addition-row ${separator}`} onClick={() => additionClick(addition, selectedItem)} key={addition.productAdditionId}>
                <div className={`${addition.groupNum > 0 && additionGroupNumCount[addition.groupNum].length > 1 ? 'radio' : 'check-box'} ${checked}`}></div>
                <span className='price'>{addition.price}</span>
                <span className='uah'>₴</span>
                <div className='name'>{i18n.prop(addition, 'name')}</div>
            </div>
        );
    });
    const isOneSizedItem = selectedProduct.orderItems.length === 1
        && selectedProduct.orderItems.filter(i => i.sizeUa).length === 0;
    const description = i18n.prop(selectedItem, 'description');
    return (
        <div className='add-to-cart'>
            <div className='overlay' onClick={closeClick}></div>
            <div className='product-container'>
                <div className='header'>
                    <div className='product-name'>{isOneSizedItem ? selectedProduct.groupName : i18n.prop(selectedItem, 'name')}</div>
                    <div className='cross-white' onClick={closeClick}></div>
                </div>
                <div className={isOneSizedItem ? 'nosize' : 'size'}>{i18n.prop(selectedItem, isOneSizedItem ? 'name': 'size')}</div>
                <div className='description'>{description}</div>
                <div className='item-row'>
                    <div className='item' disabled={selectedItem.count === 0}>
                        <div className='price'>{getOrderItemFullPrice(selectedItem)}</div>
                        <div className='uah'>₴</div>
                    </div>
                    <div className='actions'>
                        <div className='dec' onClick={() => decClick(selectedItem)} disabled={selectedItem.count <= 0}>—</div>
                        <div className='value' disabled={selectedItem.count <= 0}>{selectedItem.count}</div>
                        <div className='inc' onClick={() => incClick(selectedItem)}>+</div>
                    </div>
                </div>
                <div className={`additions ${!additions.length ? 'hidden' : ''}`}>
                    <div className='header-label'>{i18n.additions}</div>
                    {additions}
                </div>
            </div>
            <div className='footer'>
                <VestaButton
                    label={i18n.addToOrder}
                    onButtonClick={addToOrderClick}
                    image={'arrow-white'}
                />
                <div className='bottom'>
                    <div className='total-label'>{i18n.add}</div>
                    <div className='total'>{calculateTotal(selectedProduct)}</div>
                    <div className='uah'>₴</div>
                </div>
            </div>
        </div >
    );
};

export default AddToCart;