import { Cookies } from "react-cookie";

class VestaApiService {

  _apiBase = process.env.REACT_APP_APIBASEURL; // value stored in the .env file in the root folder

  _customerSessionHeader = (sessionGuid) => {
    return {
      headers: {
        'customerSession': sessionGuid ?? new Cookies().get('vesta-session')
      }
    }
  }

  async getResource(url, options) {
    //await new Promise(resolve => setTimeout(resolve, 1000));
    const res = await fetch(`${this._apiBase}${url}`, options);

    
    if (!res || !res.ok || res.status === 204) {
      return null;
    }
    return await res.json();
  }

  async signIn(phoneNumber) {
    return this.getResource(`signin/${phoneNumber}`)
  }

  async phoneexists(phoneNumber) {
    return this.getResource(`phoneexists/${phoneNumber}`)
  }

  async signInGuest() {
    return this.getResource('signinguest')
  }

  async signWithSession(sessionGuid) {
    return this.getResource(`signinsession`, this._customerSessionHeader(sessionGuid));
  }

  async register(registerData) {
    return this.getResource('register', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(registerData)
    });
  }

  async sendCode(phoneNumber) {
    return this.getResource(`sendcode/${phoneNumber}`);
  }

  async getProductGroups() {
    return this.getResource('groups');
  }

  async getAllGroups() {
    return this.getResource('allgroups');
  }

  async getSpecials() {
    return this.getResource('specials');
  }

  async getLocations() {
    return this.getResource('locations');
  }

  async saveOrder(order, customerSession) {
    return this.getResource('saveorder', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': customerSession },
      body: JSON.stringify({
        order: order,        
      })
    });
  };

  async getLastOrder(customerSession) {
    return this.getResource(`getlastorder`, this._customerSessionHeader(customerSession));
  }

  async getLastTempOrder(customerSession) {
    return this.getResource(`getlastorder?state=0`
    , this._customerSessionHeader(customerSession));
  }

  async getAllOrders(customerSession) {
    return this.getResource(`getallorders`, this._customerSessionHeader(customerSession));
  }

  async setOrderState(orderId, state, sessionGuid) {
    return this.getResource(`orderstate?orderId=${orderId}&state=${state}`
    , this._customerSessionHeader(sessionGuid));
  }

  async signOrder(orderId, sessionGuid, orderValues) {
    return this.getResource(`signOrder/${orderId}?orderValues=${orderValues}`,  this._customerSessionHeader(sessionGuid));
  }
  
  async postFeedback(feedback, rating, customerSession) {
    return this.getResource('postfeedback', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': customerSession },
      body: JSON.stringify({
        feedback: feedback,
        rating: rating
      })
    });
  };
  
  //--------admin
  async getFeatures() {
    return this.getResource(`admin/features`, this._customerSessionHeader());
  }

  async getCustomers() {
    return this.getResource(`admin/customers`, this._customerSessionHeader());
  }

  async findCustomer(context) {
    return this.getResource(`admin/findcustomer/${context}`, this._customerSessionHeader());
  }

  async setBonus(customerId, bonus) {
    return this.getResource('admin/setbonus', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify({
        customerId: customerId,
        bonuses: parseInt(bonus, 10)
      })
    });
  }

  async getRoles() {
    return this.getResource(`admin/roles`, this._customerSessionHeader());
  }

  async addRole(name) {
    return this.getResource(`admin/addrole`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify({
        name: name
      })
    });
  }

  async removeRole(role) {
    return this.getResource(`admin/removerole`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify(role)
    });
  }

  async addFeatureRole(featureId, roleId) {
    return this.getResource(`admin/addfeaturerole`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify({
        featureId: featureId,
        roleId: roleId
      })
    });
  }

  async removeFeatureRole(featureRole) {
    return this.getResource(`admin/removefeaturerole`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify(featureRole)
    });
  }

  async addCustomerRole(cusromerId, roleId) {
    return this.getResource(`admin/addcustomerrole`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify({
        customerId: cusromerId,
        roleId: roleId
      })
    });
  }

  async removeCustomerRole(customerRole) {
    return this.getResource(`admin/removecustomerrole`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify(customerRole)
    });
  }

  async saveLocation(location) {
    return this.getResource(`admin/savelocation`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify(location)
    });
  }

  async saveGroup(group){
    return this.getResource(`admin/savegroup`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify(group)
    });
  }

  async saveProduct(product){
    return this.getResource(`admin/saveproduct`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify(product)
    });
  }

  async saveAddition(addition){
    return this.getResource(`admin/saveaddition`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify(addition)
    });
  }

  async removeProductItems(productItems){
    return this.getResource(`admin/removeproductitems`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify(productItems)
    });
  }

  async removeProductAdditions(productAdditions){
    return this.getResource(`admin/removeproductadditions`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify(productAdditions)
    });
  }

  async removeProductAdditionOverrides(productAdditionOverrides){
    return this.getResource(`admin/removeproductadditionoverrides`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'customerSession': new Cookies().get('vesta-session') },
      body: JSON.stringify(productAdditionOverrides)
    });
  }

  async getAllGroupsWithProducts() {
    return this.getResource(`admin/allgroupswithproducts`, this._customerSessionHeader());
  }

  async getProduct(id) {
    return this.getResource(`admin/product/${id}`, this._customerSessionHeader());
  }

  async getAdditions() {
    return this.getResource('admin/additions', this._customerSessionHeader());
  }
};

export default VestaApiService;