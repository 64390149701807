import React from 'react'
import './manage-locations.css';
import { formatHour } from '../../../common'
import Spinner from '../../spinner';
import EditLocation from './edit-location';
import { Route, Switch } from 'react-router-dom';


class ManageLocations extends React.Component {

    state = {
        isLoading: false,
        locations: [],
        newLocationName: ''
    };

    newLocationNameChanged = (e) => {
        this.setState({
            newLocationName: e.target.value
        })
    };

    addNewLocation = () => {
        const { history } = this.props;
        history.push('/admin/managelocations/0');
    };

    loadLocations = (loading, force) => {
        const { apiService, id } = this.props;
        if (id && !force) {
            return;
        }
        if (!loading) {
            this.setState({ isLoading: true });
        }
        apiService.getLocations()
            .then(res => {
                this.setState({
                    locations: res ?? [],
                    isLoading: false
                })
            });
    }

    componentDidMount = () => {
        this.loadLocations();
    }

    render() {
        const { locations, isLoading } = this.state;
        const { history } = this.props;
        if (isLoading) {
            return <Spinner onTop />
        }
        const locationsList = locations.map(l => (
            <div className={`location-row ${l.isActive ? '' : 'not-active'}`} key={l.locationId}>
                <div className='name'>{l.addressUa} ({formatHour(l.openHour)}-{formatHour(l.closeHour)})</div>
                <div className='actions'>
                    <div className='edit action-button' onClick={() => history.push(`/admin/managelocations/${l.locationId}`)}>Редагувати</div>
                </div>
            </div>
        ));

        const locationsView = (
            <div className='manage-locations'>
                <div className='locations'>
                    {locationsList}
                </div>
                <hr />
                <div className='add-new'>
                    <div className='add action-button' onClick={this.addNewLocation}>Додати</div>
                </div>
            </div>);

        return (
            <Switch>
                <Route path="/admin/managelocations/:id" render={(props) =>
                    <EditLocation
                        id={props.match.params.id}
                        history={history}
                        onBack={() => { history.push('/admin/managelocations'); this.loadLocations(true, true); }}
                        apiService={this.props.apiService} />} />
                <Route>
                    {locationsView}
                </Route>

            </Switch>
        );
    };
};

export default ManageLocations;