import React from 'react'
import './feedback-rate.css'

const FeedbackRate = ({
    rateChanged,
    rate
}) => {

    const rateMap = [1, 2, 3, 4];
    const rates = rateMap.map(r => (
        <div
            className={`rate-item ${r <= rate ? 'selected' : ''}`}
            key={'rate' + r.toString()}
            onClick={() => rateChanged(r)}>
        </div>
    ));

    return (
        <div className='feedback-rate'>
            {rates}
        </div>
    )
};

export default FeedbackRate;