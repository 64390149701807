import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import withApiService from '../../hoc/with-api-service';
import { withRouter } from 'react-router-dom'
import VestaButton from '../../vesta-button';
import Header from '../../header';
import OrderBox from '../../order-box';
import Spinner from '../../spinner'
import './orders-page.css';
import { cleanOrderIdentities } from '../../../reducers/common';
import { orderPaymentRequested } from '../../../actions'

class OrdersPage extends React.Component {

    state = {
        orders: null,
        loading: false
    }

    componentDidMount() {
        const { apiService, sessionGuid } = this.props;
        apiService
            .getAllOrders(sessionGuid)
            .then(result => {
                if (result.success && result.orders) {
                    this.setState({ orders: result.orders });
                }
            });
    };

    orderAgain = (oldOrder) => {
        const { history, apiService, sessionGuid, orderPaymentRequested, vestaLocation } = this.props;
        const cleanedOldOrder = cleanOrderIdentities(oldOrder);
        this.setState({
            loading: true
        });
        apiService.getLastTempOrder(sessionGuid)
            .then(result => {
                let newOrder = result.order && result.order.orderItems
                    ? {
                        ...result.order,
                        orderItems: cleanedOldOrder.orderItems
                    }
                    : cleanedOldOrder;
                if(!newOrder.location){
                    newOrder.location = vestaLocation;
                }
                apiService.saveOrder(newOrder, sessionGuid)
                    .then(result => {
                        this.setState({
                            loading: false
                        });
                        if (result.success) {
                            history.push('/products');
                            orderPaymentRequested();
                        }                
                    });

            });
    }

    render() {
        const { customer, i18n, history } = this.props;
        const { orders } = this.state;

        const ordersView = orders
            ? orders.map(order => (
                <div className='order' key={order.orderId}>
                    <OrderBox
                        order={order}
                        i18n={i18n}
                        buttonLabel={i18n.orderAgain}
                        orderAgainClick={() => this.orderAgain(order)}
                    />
                </div>
            ))
            : <Spinner />;

        if (!customer) {
            return <Redirect to='/' />
        }

        if (this.state.loading) {
            return <Spinner />
        }

        return (
            <div className='orders-page'>
                <Header goHomeOnSignOut />
                <div className='body'>
                    <div className='last-orders'>{i18n.lastOrders}</div>
                    {ordersView}
                </div>
                <div className='footer'>
                    <VestaButton
                        preImage={'arrow-left'}
                        label={i18n.back}
                        onButtonClick={() => history.goBack()}
                    />
                </div>
            </div>
        );
    };
}

const mapStateToProps = ({ customer, i18n, sessionGuid }) => {
    return { customer, i18n, sessionGuid }
};

export default connect(mapStateToProps, { orderPaymentRequested })
    (withRouter(withApiService(OrdersPage)));