import React, { Component } from 'react';
import Product from '../product';
import './product-group.css';

class ProductGroup extends Component {
    state = {
        clickedHeaderId: 0
    };

    headerClicked = (product) => {
        this.setState({
            clickedHeaderId: product.productId
        });
        setTimeout(() => {
            this.setState({
                clickedHeaderId: 0
            });
        }, 1000);
    }

    render() {
        const { productGroup, i18n, itemClick, order, orderItemClick } = this.props;
        const productView = productGroup.products.filter(p => p.isActive).map(product => (
            <Product
                orderItemClick={orderItemClick}
                key={product.productId}
                itemClick={itemClick}
                i18n={i18n}
                order={order}
                product={{...product, groupName: i18n.prop(productGroup, 'name')}} />
        ));
        return (
            <div className='product-group'>
                <div className='header'>
                    <div className='header-title'>{i18n.prop(productGroup, 'name')}</div>
                </div>
                <div className='content'>
                    {productView}
                </div>
            </div>
        );
    };
};

export default ProductGroup;