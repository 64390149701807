import React from 'react';
import { useSpring, animated } from 'react-spring'
import { getOrderItemFullPrice } from '../../common'
import './product-item.css'

const ProductItem = ({ productItem, i18n, itemClick, blink }) => {

    const backgroundProps = useSpring({
        reset: true,
        from: {
            backgroundColor: '#cccccc',
            borderRadius: '20px'
        },
        to: [{
            backgroundColor: 'white',
            config: { duration: 400 }
        }]
    });

    const backgroundPropsSpecial = useSpring({
        reset: true,
        from: {
            backgroundColor: '#cccccccc',           
            borderRadius: '20px'
        },
        to: [{
            backgroundColor: '#ffffff00',
            config: { duration: 400 }
        }]
    });

    return (
        <animated.div style={blink ? (productItem.oldPrice ? backgroundPropsSpecial : backgroundProps) : null} className='product-item' onClick={itemClick} >
            {productItem.oldPrice ? <div className='old-price'>{productItem.oldPrice}</div> : null}
            <div className='price'>{getOrderItemFullPrice(productItem)}</div>
            <div className='uah'>₴</div>
            <div className='size'>{i18n.prop(productItem, 'size')}</div>
        </animated.div>
    );
};

export default ProductItem;