import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom'
import withApiService from '../../hoc/with-api-service';
import './payment-fail-page.css'
import VestaButton from '../../vesta-button';
import { orderUpdated, orderPaymentRequested } from '../../../actions';
import ReactGA from 'react-ga';
import { calculateTotal } from '../../../common';

class PaymentFailPage extends React.Component {

    componentDidMount() {
        const { apiService, orderUpdated, sessionGuid } = this.props;
        apiService.getLastTempOrder(sessionGuid)
            .then(result => {
                if (result.success && result.order) {
                    ReactGA.event({
                        category: 'Order',
                        action: 'Payment failed',
                        label: result.order.orderId.toString(),
                        value: calculateTotal(result.order)
                      });
                    orderUpdated(result.order);
                }
            });
    }

    paymentRetry = () => {
        const { history, orderPaymentRequested, order } = this.props;
        const tempOrderExists = order && order.orderItems && order.orderItems.length;
        if (tempOrderExists) {
            orderPaymentRequested();
            history.push('/products');
        } else {
            history.push('/products');
        }
    }
    render() {
        const { i18n, history, customer } = this.props;
        if(!customer){
            return <Redirect to='/' />
        }
    
        return (
            <div className='payment-fail account-bg'>
                <div className='kredens-logo' onClick={() => history.push('/')}></div>
                <div className='sorry'>{i18n.yourPaymentDeclined}</div>
                <div className='description'>{i18n.tryPaymentAgain}</div>
                <VestaButton
                    theme={'black'}
                    label={i18n.retryPayment}
                    onButtonClick={this.paymentRetry}
                />
                <VestaButton
                    label={i18n.backToOrder}
                    theme={'whitesolid'}
                    onButtonClick={() => history.push('/')}
                />
            </div>
        )
    }
};

const mapStateToProps = ({ customer, i18n, order, sessionGuid }) => {
    return { customer, i18n, order, sessionGuid }
};
export default connect(mapStateToProps, { orderUpdated, orderPaymentRequested })(withApiService(withRouter(PaymentFailPage)));
