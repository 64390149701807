import React from 'react';

const DeliveryInfo = () => {
    return (
        <div className='delivery-info'>
            <div className='par'>
                Доставка зернової кави та випічки <b>кур'єром по Львову з 09:00 до 16:00</b> та через відділення "Нова Пошта" в день оформлення замовлення відповідно до графіків роботи служб доставки в день замовлення.
                </div>
            <div className='par'>
                <b>Доставляємо безкоштовно при замовленні понад 250 ₴.</b>
            </div>

        </div>
    )
}

export default DeliveryInfo;