import React from 'react';
import VestaButton from '../../vesta-button';
import CartHeader from './cart-header';
import { getUniqueItemKey, itemsAreEqual  } from '../../../reducers/common'
import { calculateTotal, getOrderItemFullPrice } from '../../../common'
import './edit-cart.css'

const EditCart = ({ order, incClick, decClick, removeItemClick, i18n, closeClick, confirmedClick }) => {

    const itemView = (item) => (
        <div className='item-row'>
            <div className='item' disabled={item.count === 0}>
                <div className='price'>{getOrderItemFullPrice(item)*item.count}</div>
                <div className='uah'>₴</div>
            </div>
            <div className='actions'>
                <div className='dec' onClick={() => decClick(item)} disabled={item.count <= 0}>—</div>
                <div className='value'>{item.count}</div>
                <div className='inc' onClick={() => incClick(item)}>+</div>
            </div>
        </div>
    );
    const products = order.orderItems.map(item => {
        const separator = itemsAreEqual(item, order.orderItems[0])
            ? null
            : <div className="separator"></div>

        const additions = item.orderItemAdditions.map(addition => {
            return (
                <div className='addition-row' key={addition.productAdditionId}>
                    <div className={`check-box checked`}></div>
                    <span className='price'>{addition.price}</span>
                    <span className='uah'>₴</span>
                    <div className='name'>{i18n.prop(addition, 'name')}</div>
                </div>
            );
        });
        const additionsList = item.orderItemAdditions.map(a => i18n.prop(a, 'name')).join(', ');

        const additionsView = additions.length > 0 ? (
            <div className={`additions ${!additions.length ? 'hidden' : ''}`}>
                {additionsList}
            </div>
        ) : null;
        return (
            <div className='product-container' key={getUniqueItemKey(item)}>
                {separator}
                <div className='product'>
                    <div className='header'>
                        <div className='header-title'>
                            <div className='remove' onClick={() => removeItemClick(item)}></div>
                            <div className='product-name'>{i18n.prop(item, 'name')}</div>
                        </div>
                        <div className='size'>{i18n.prop(item, 'size')}</div>
                    </div>
                    {additionsView}
                    <div className='items'>
                        {itemView(item)}
                    </div>
                </div>
            </div>
        )
    });

    const empty = (
        <div className="empty-order">
            <div className="empty-logo empty-cart" />
            <div className='empty-description line1'>{i18n.emptyOrder1}</div>
            <div className='empty-description line2'>{i18n.emptyOrder2}</div>
        </div>
    );

    const bottom = order.orderItems.length === 0
        ? (
            <div className='bottom-empty'>{i18n.weHaveGoods}</div>
        )
        : (
            <div className='bottom'>
                <div className='total-label'>{i18n.totalLabel}</div>
                <div className='total'>{calculateTotal(order)}</div>
                <div className='uah'>₴</div>
            </div>
        );


    const overflowClass = order.orderItems.length > 3 ? 'overflow' : '';

    return (
        <div className='edit-cart'>
            <div className='overlay' onClick={closeClick}></div>
            <CartHeader title={i18n.editOrder} closeClick={closeClick} />
            <div className={`products ${overflowClass}`}>
                {order.orderItems.length > 0 ? products : empty}
            </div>
            <div className='footer'>
                <VestaButton
                    label={i18n.finishEdit}
                    onButtonClick={confirmedClick}
                />
                {bottom}
            </div>
        </div >
    );
};

export default EditCart;