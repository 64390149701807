import React from 'react';
import './order-box.css';
import VestaButton from '../vesta-button';
import { calculateTotal } from '../../common';

const OrderBox = ({ buttonLabel, orderAgainClick, order, i18n, className }) => {

    const itemsView = order.orderItems.map(item => (
        <div className='item-row' key={item.orderItemId}>
            <div className='name'>{i18n.prop(item, 'name')}</div>
            <div className='count'>x {item.count}</div>
        </div>
    ));

    const total = calculateTotal(order);

    return (
        <div className={`order-box ${className ? className : ''}`}>
            <div className='items'>{itemsView}</div>
            <div className='bottom'>
                <div className='total'>{total} ₴</div>
                <VestaButton
                    theme='whitesolidborder'
                    label={buttonLabel}
                    onButtonClick={orderAgainClick}
                />
            </div>
        </div>
    );
};

export default OrderBox;