import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import VestaApp from './components/app/app';
import ErrorBoundry from './components/error-boundry';
import store from './store';
import VestaApiService from './services/vesta-api-service';
import VestaApiServiceContext from './components/vesta-api-service-context';

const apiService = new VestaApiService();


ReactDOM.render(
    (
        <Provider store={store}>
            <ErrorBoundry>
                <VestaApiServiceContext.Provider value={apiService}>
                    <Router>
                        <VestaApp />
                    </Router>
                </VestaApiServiceContext.Provider>
            </ErrorBoundry>
        </Provider>
    ), document.getElementById('root'));