import React from 'react';
import './cart-header.css';

const CartHeader = ({ title, closeClick }) => {
    return (
        <div className='cart-header'>
            <div className='label'>
                <span>{title}</span>
            </div>
            <div className='close' onClick={closeClick}></div>
        </div>
    );
};

export default CartHeader;