import React, { Component } from 'react';
import ProductItem from '../product-item';
import { getOrderItemFullPrice } from '../../common';
import { getUniqueItemKey } from '../../reducers/common'
import './product.css';

class Product extends Component {
    state = {
        clickedHeaderId: 0
    };

    headerClicked = (product) => {
        this.setState({
            clickedHeaderId: product.productId
        });
        setTimeout(() => {
            this.setState({
                clickedHeaderId: 0
            });
        }, 1000);
    }

    render() {
        const { product, i18n, itemClick, order, orderItemClick } = this.props;

        const isOneSizedItem = product.productItems.length === 1 && product.productItems.filter(i => i.sizeUa).length === 0;

        const itemsInOrder = order.orderItems.filter(item =>
            product.productItems.filter(i => i.productItemId === item.productItemId).length > 0);

        const itemsInOrderView = itemsInOrder.map(item => {
            const additionsList = item.orderItemAdditions.map(a => i18n.prop(a, 'name')).join(', ');
            return (
                <div className='order-item' key={`orderItem${getUniqueItemKey(item)}`} onClick={() => orderItemClick(product, item)}>
                    <div className='record'>
                        <div className='size'>{i18n.prop(item, isOneSizedItem ? 'name' : 'size')}</div>
                        <div className='count'>x {item.count}</div>
                        <div className='price'>{item.count * getOrderItemFullPrice(item)}</div>
                        <div className='uah'>₴</div>
                    </div>
                    <div className='additions'>{additionsList}</div>
                </div>
            )
        });

        const itemsInOrderContainer = itemsInOrder.length > 0
            ? (
                <div className='order-items'>
                    <div className="order-items-header">{i18n.youOrdered}</div>
                    {itemsInOrderView}
                </div>
            ) : null;

        const productSizesView = product.productItems.map(item => {
            const orderedItemCount = order.orderItems
                .filter(i => i.productItemId === item.productItemId)
                .reduce((r, i) => r + i.count, 0);
            return (
                <ProductItem
                    productItem={item}
                    i18n={i18n}
                    key={item.productItemId}
                    orderedItemCount={orderedItemCount}
                    itemClick={() => itemClick(product, item)}
                    blink={this.state.clickedHeaderId === product.productId ? true : false}
                />
            );
        });
        const headerClick = isOneSizedItem ? () => itemClick(product, product.productItems[0]) : () => this.headerClicked(product);
        const oneSize = isOneSizedItem ? 'one-size' : '';
        const ordered = itemsInOrder.length ? 'ordered' : '';
        const specialBg = product.specialBackgroundUrl ?? '';

        return (
            <div className={`product-component ${oneSize} ${ordered} ${specialBg}`}>
                <div className='product-container'>
                    <div className='product-header' onClick={headerClick}>
                        {i18n.prop(product, 'name')}
                    </div>
                    {product.specialBackgroundUrl ? <div className='product-description'>{i18n.prop(product, 'specialDescription')}</div> : null}
                    <div className='product-sizes'>
                        {productSizesView}
                    </div>
                </div>
                {itemsInOrderContainer}
            </div>
        );
    };
};

export default Product;